import _ from 'lodash'
import moment from 'moment'
import create from 'zustand'

import { Turn } from '../views/Books/Debug'

interface SavedTurn {
  turn: Turn
  date: moment.Moment
}

interface State {
  savedTurns: { [key: string]: SavedTurn[] }
  addSavedTurn: (bookId: string, artefactId: string, turn: Turn) => void
  updateSavedTurn: (bookId: string, artefactId: string, turn_id: string, turn: Turn) => void
  pruneTurns: (bookId: string, artefactId: string) => void
}

const getKey = (bookId: string, artefactId: string) => `${bookId}${artefactId}`

export const useTurnsStore = create<State>()(set => ({
  savedTurns: {},
  addSavedTurn: (bookId: string, artefactId: string, turn: Turn) =>
    set(state => {
      const key = getKey(bookId, artefactId)
      return {
        savedTurns: {
          ...state.savedTurns,
          [key]: [...(state.savedTurns[key] || []), { turn, date: moment() }]
        }
      }
    }),
  updateSavedTurn: (bookId: string, artefactId: string, turn_id: string, turn: Turn) =>
    set(state => {
      const key = getKey(bookId, artefactId)
      return {
        savedTurns: {
          ...state.savedTurns,
          [key]: [...state.savedTurns[key].filter(x => x.turn.turn_id !== turn_id), { turn, date: moment() }]
        }
      }
    }),
  pruneTurns: (bookId: string, artefactId: string) =>
    set(state => {
      const key = getKey(bookId, artefactId)
      const withoutOlder = state.savedTurns[key].filter(x => x.date.diff(moment(), 'm') < 60)

      return {
        savedTurns: {
          ...state.savedTurns,
          [key]: _.takeRight(withoutOlder, 10)
        }
      }
    })
}))
