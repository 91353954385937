import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'

import api, { StoredInfo } from '../../api'
import { useBookStore } from '../../stores/books'
import { useAuth, useClient } from '../../supabase'
import storage from '../../utils/storage'

const DELAY_BETWEEN_SAVE = 20000

interface Props {
  setLoaded: (value: boolean) => void
}

const UserLoader: FC<Props> = (props) => {
  const { isAuthenticated } = useAuth()
  const [setBooks] = useBookStore((state) => [state.setBooks])
  const [isLoaded, setLoaded] = useState(false)
  const [refreshInterval, setRefreshInterval] = useState<any>()
  const client = useClient()

  useEffect(() => {
    if (isAuthenticated) {
      void loadUser()
    }
  }, [isAuthenticated])

  useEffect(() => {
    const debounceSave = _.debounce(async (value) => {
      try {
        await api.saveUserData(client, value)
      } catch (err) {
        console.error('Error while saving', err)
      }
    }, DELAY_BETWEEN_SAVE)

    const unsubscribe = useBookStore.subscribe((change) => {
      // Prevent updating the date before the user is loaded, to prevent overwriting remote data
      if (isLoaded) {
        storage.set('modifiedOn', new Date())
      }

      return debounceSave({ books: change.books, saveDate: new Date() })
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const isDataExpired = (storageDate?: Date, dbDate?: Date) => {
    return storageDate === undefined || moment(storageDate).isBefore(dbDate)
  }

  const startTokenRefresher = async (clientId: string, clientSecret: string) => {
    const result = await api.refreshToken(clientId, clientSecret)

    if (!result || refreshInterval) {
      return
    }

    setRefreshInterval(
      setInterval(async () => {
        await api.refreshToken(clientId, clientSecret)
        console.info('Token refreshed')
      }, result.expires_in * 1000)
    )

    setLoaded(true)
  }

  const updateBookStorage = (data?: StoredInfo) => {
    const storageDate = storage.get<Date>('modifiedOn')

    if (data !== undefined && data.books && isDataExpired(storageDate, data.saveDate)) {
      setBooks(data.books)
    }
  }

  const updateObCredentials = async () => {
    const session = client.auth.session()

    try {
      const { data } = await axios.get('/api/get_ob_credentials', {
        headers: { Authorization: `Bearer ${session?.access_token}` }
      })
      return data
    } catch (err) {
      console.error(err)
    }
  }

  const loadUser = async () => {
    try {
      await updateObCredentials()
      const storedData = await api.loadUserData(client)

      if (!storedData || !storedData.client_id || !storedData.client_secret) {
        return
      }

      // Scope the storage to the logged in user
      useBookStore.persist.setOptions({ name: `books-storage-${storedData.client_id}` })
      await useBookStore.persist.rehydrate()

      updateBookStorage(storedData.data)

      // Clear old key
      localStorage.removeItem('books-storage')

      await startTokenRefresher(storedData.client_id, storedData.client_secret)
    } catch (err) {
      console.error('Error while loading remote data', err)
    } finally {
      props.setLoaded(true)
    }
  }

  return <div />
}

export default UserLoader
