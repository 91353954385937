import { createClient } from '@supabase/supabase-js'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom'
import './style/index.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './style/App.css'
import './style/Blueprint.css'

import reportWebVitals from './reportWebVitals'
import { useAuth, SupabaseProvider } from './supabase'
import App from './views/App'
import Debug from './views/Books/Debug'
import Edit from './views/Books/Edit'
import Home from './views/Home'
import Login from './views/Login'

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
}

const client = createClient(
  'https://tslbposnaptsfoexbppr.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRzbGJwb3NuYXB0c2ZvZXhicHByIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTI5MDUzOTYsImV4cCI6MTk2ODQ4MTM5Nn0.pD9R4ZvNWiA7M2edctRferaU4veB-mowysdhd430FsE'
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <SupabaseProvider value={client}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<Home />} />
            <Route path="/books/:bookId/:artefactId/debug" element={<Debug />} />
            <Route path="/books/:bookId/:artefactId/edit" element={<Edit />} />
          </Route>
          <Route path="/login" element={<Login />} />

          <Route
            path="*"
            element={
              <main style={{ padding: '1rem' }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </SupabaseProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
