import React, { useState, useRef, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ResizePanel from 'react-resize-panel'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import api, { Feedback } from '../../../api'
import { useBookStore } from '../../../stores/books'
import { useTurnsStore } from '../../../stores/turns'

import AnswerLevelPicker, { AnswerLevel } from './components/AnswerLevelPicker'
import DebugMenu from './components/DebugMenu'
import HistoryLengthPicker from './components/HistoryLengthPicker'
import NoQueryEmptyState from './components/NoQueryEmptyState'
import NoTestEmptyState from './components/NoTestEmptyState'
import QueryBox from './components/QueryBox'
import TopFactPicker from './components/TopFactPicker'
import DEBUG_OBJ from './example_debug'
import History from './History'
import Inspector from './Inspector'
import Pinned from './Pinned'

import './debug.css'

export interface Turn {
  turn_id: string
  user_question_text: string
  user_question_ts: number
  answer_status: 'pending' | 'done' | 'error'
  answer_ts?: number
  answer_text?: string
  answer_attachments?: string[]
  answer_id?: string
  answer_error?: string
  answer_debug?: any
  feedback?: number
}

const INITIAL_TURNS: Turn[] = [
  {
    turn_id: 'initial-1',
    user_question_text: 'How I use the Playground?',
    user_question_ts: Date.now() - 2000,
    answer_status: 'done',
    answer_ts: Date.now(),
    answer_text: 'Simply ask your book a question in the box below, then click on any message to inspect it.',
    answer_attachments: ['__playground:start_tutorial:Tutorial'],
    answer_id: 'initial-1-answer',
    answer_debug: DEBUG_OBJ // TODO: Uncomment to easily test and debug the right panel without sending messages
  }
]

export default function Debug() {
  const [historyLength, setHistoryLength] = useState(0)
  const [topFacts, setTopFacts] = useState(5)
  const [answerLevel, setAnswerLevel] = useState<AnswerLevel>('medium')
  const [queryPending, setQueryPending] = useState(false)
  const [selectedTurnId, setSelectedTurnId] = useState<string | undefined>()
  const [turns, setTurns] = useState<Turn[]>([...INITIAL_TURNS])
  const params = useParams()
  const navigate = useNavigate()
  const scrollbar = useRef<HTMLElement>()
  const selectedTurn = turns.find((x) => x.turn_id === selectedTurnId)
  const bookId = params.bookId!
  const artefactId = params.artefactId!
  const [savedQueries, addSavedQuery, setSavedQueries] = useBookStore((state) => [
    state.books[bookId].savedQueries || [],
    state.addSavedQuery,
    state.setSavedQueries,
    state.getSavedQueries
  ])
  const [savedTurns, addSavedTurn, updateSavedTurn, pruneTurns] = useTurnsStore((state) => [
    state.savedTurns[`${bookId}${artefactId}`],
    state.addSavedTurn,
    state.updateSavedTurn,
    state.pruneTurns
  ])

  useEffect(() => {
    setTurns([...INITIAL_TURNS, ...(savedTurns || []).map((x) => x.turn)])
  }, [bookId, artefactId])

  const sendQuery = async (query: string) => {
    let turn: Turn = {
      turn_id: uuid(),
      user_question_text: query,
      user_question_ts: Date.now(),
      answer_status: 'pending'
    }
    setQueryPending(true)
    setTurns([...turns, turn])
    try {
      const res = await api.queryBookArtifact(bookId, artefactId, query, [], answerLevel)
      turn = {
        ...turn,
        answer_attachments: [],
        answer_status: 'done',
        answer_id: res.id,
        answer_text: res.answer,
        answer_ts: Date.now(),
        answer_debug: res.debug
      }
      setTurns([...turns.filter((x) => x.turn_id !== turn.turn_id), turn])
      addSavedTurn(bookId, artefactId, turn)
      pruneTurns(bookId, artefactId)
      setSelectedTurnId(turn.turn_id)
    } catch (err) {
      if (err instanceof Error) {
        turn = {
          ...turn,
          answer_attachments: [],
          answer_status: 'error',
          answer_error: err?.message || 'Unknown',
          answer_ts: Date.now()
        }
        setTurns([...turns.filter((x) => x.turn_id !== turn.turn_id), turn])
        addSavedTurn(bookId, artefactId, turn)
        pruneTurns(bookId, artefactId)
      }
    } finally {
      setQueryPending(false)
    }
  }

  const sendFeedback = async (turn: Turn, feedback: number) => {
    const newTurn = { ...turn, feedback }
    setTurns([...turns.filter((x) => x.turn_id !== turn.turn_id), newTurn])
    updateSavedTurn(bookId, artefactId, turn.turn_id, newTurn)

    await api.sendQueryFeedback(params.bookId!, params.artefactId!, turn.answer_text!, feedback as Feedback)
  }

  const pinQuestion = (question: string) => {
    if (!savedQueries.find((x) => x.question === question)) {
      addSavedQuery(bookId, { question })
    }
  }

  const removeSavedQuery = (question: string) => {
    setSavedQueries(
      bookId,
      savedQueries.filter((x) => x.question !== question)
    )
  }

  return (
    <React.Fragment>
      <ResizePanel
        direction="e"
        handleClass={'customHandle'}
        borderClass={'customResizeBorder'}
        style={{ flexGrow: '1', flexShrink: '0' }}
      >
        <div className={'sidebar panel'} style={{ textAlign: 'left', padding: 10 }}>
          {!savedQueries.length ? (
            <NoTestEmptyState />
          ) : (
            <Pinned
              queries={savedQueries}
              onRemoveQuery={(question) => removeSavedQuery(question)}
              onSendQuery={(question) => sendQuery(question)}
            />
          )}
        </div>
      </ResizePanel>
      <div className={'content panel'} style={{ flexGrow: '5', flexBasis: 0, maxHeight: 'calc(100vh - 92px)' }}>
        <div className="conversation">
          <PerfectScrollbar
            style={{ flexGrow: '1', width: '100%', overflowY: 'scroll' }}
            containerRef={(ref) => (scrollbar.current = ref)}
          >
            <History
              scrollbarRef={scrollbar}
              activeTurnId={selectedTurnId}
              onTurnSelected={(tid) => setSelectedTurnId(tid)}
              turns={turns}
              sendFeedback={sendFeedback}
              pinQuestion={pinQuestion}
            />
          </PerfectScrollbar>

          <div
            style={{
              flexShrink: '0',
              minHeight: '55px',
              minWidth: '400px',
              width: '100%',
              padding: '15px 15px 5px 15px'
            }}
          >
            <QueryBox queryPending={queryPending} sendQuery={sendQuery} bookId={bookId} artefactId={artefactId} />
            <div className="bp4-text-small query-params">
              <HistoryLengthPicker onChange={setHistoryLength} />
              <TopFactPicker onChange={setTopFacts} />
              <AnswerLevelPicker onChange={setAnswerLevel} />
            </div>
          </div>
        </div>
      </div>
      <ResizePanel
        direction="w"
        style={{ flexGrow: '1', flexShrink: '0' }}
        handleClass={'customHandle'}
        borderClass={'customResizeBorder'}
      >
        <div style={{ padding: '10px' }} className={'sidebar panel'}>
          {selectedTurnId ? (
            <Inspector debug_obj={selectedTurn?.answer_debug} bookId={params.bookId} artefactId={params.artefactId} />
          ) : (
            <NoQueryEmptyState />
          )}
        </div>
      </ResizePanel>

      <DebugMenu turns={turns} setTurns={setTurns} />
    </React.Fragment>
  )
}
