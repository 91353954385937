const NoQueryEmptyState: React.FC<{}> = props => (
  <div className="nonIdealTab">
    <div className="bp4-non-ideal-state">
      <div className="bp4-non-ideal-state-visual">
        <span className="bp4-icon bp4-icon-predictive-analysis" />
      </div>
      <h4 className="bp4-heading">Send a request first</h4>
      <div style={{ maxWidth: '250px' }}>Start by sending a query to OpenBook to see the details here.</div>
    </div>
  </div>
)

export default NoQueryEmptyState
