import _ from 'lodash'
import create from 'zustand'
import { persist } from 'zustand/middleware'

export interface BookFile {
  content: string
  location: string
}

export interface Book {
  bookId: string
  title: string
  description: string
  artefactId?: string
  lastOpened?: Date
  files: BookFile[]
  savedQueries?: SavedQuery[]
  history?: string[]
}

export interface SavedQuery {
  question: string
}

export interface BookEntry {
  [bookId: string]: Book
}

interface State {
  books: BookEntry
  setBooks: (books: BookEntry) => void
  addBook: (book: Book) => void
  updateBook: (bookId: string, changes: Partial<Book>) => void
  removeBook: (bookId: string) => void

  addFile: (bookId: string, file: BookFile) => void
  updateFile: (bookId: string, location: string, newFile: BookFile) => void
  getFiles: (bookId: string) => BookFile[]
  setFiles: (bookId: string, files: BookFile[]) => void

  addSavedQuery: (bookId: string, query: SavedQuery) => void
  getSavedQueries: (bookId: string) => SavedQuery[]
  setSavedQueries: (bookId: string, savedQueries: SavedQuery[]) => void
}

export const useBookStore = create<State>()(
  persist(
    (set, get) => ({
      books: {},
      setBooks: (newBooks: BookEntry) => set(() => ({ books: newBooks })),
      addBook: (book: Book) => set((state) => ({ books: { ...state.books, [book.bookId]: book } })),
      updateBook: (bookId: string, changes: Partial<Book>) =>
        set((state) => ({ books: { ...state.books, [bookId]: { ...state.books[bookId], ...changes } } })),
      removeBook: (bookId: string) => set((state) => ({ books: _.omit(state.books, [bookId]) })),
      addFile: (bookId: string, file: BookFile) =>
        set((state) => ({
          books: { ...state.books, [bookId]: { ...state.books[bookId], files: [...state.books[bookId].files, file] } }
        })),
      getFiles: (bookId: string) => get().books[bookId].files || [],
      setFiles: (bookId: string, files: BookFile[]) =>
        set((state) => ({
          books: { ...state.books, [bookId]: { ...state.books[bookId], files } }
        })),
      updateFile: (bookId: string, location: string, newFile: BookFile) =>
        set((state) => ({
          books: {
            ...state.books,
            [bookId]: {
              ...state.books[bookId],
              files: [...state.books[bookId].files.filter((x) => x.location !== location), newFile]
            }
          }
        })),
      getSavedQueries: (bookId: string) => get().books[bookId].savedQueries || [],
      setSavedQueries: (bookId: string, savedQueries: SavedQuery[]) =>
        set((state) => ({
          books: { ...state.books, [bookId]: { ...state.books[bookId], savedQueries } }
        })),
      addSavedQuery: (bookId: string, savedQuery: SavedQuery) =>
        set((state) => ({
          books: {
            ...state.books,
            [bookId]: {
              ...state.books[bookId],
              savedQueries: [...(state.books[bookId].savedQueries || []), savedQuery]
            }
          }
        }))
    }),

    {
      name: 'books-storage'
    }
  )
)
