import moment from 'moment'

import { TokenResponse } from '../api'

import storage from './storage'

interface StoredToken {
  token: string
  expiresAt: number
}

const TOKEN_KEY = 'token'

export const getToken = (onlyToken: boolean = true): StoredToken | string | undefined => {
  const parsedToken = storage.get<StoredToken>(TOKEN_KEY)
  return onlyToken ? parsedToken && parsedToken.token : parsedToken
}

export const setToken = (response: Partial<TokenResponse>): void => {
  const storedToken: StoredToken = {
    token: response.access_token!,
    expiresAt: moment().add(response.expires_in, 's').unix()
  }

  storage.set(TOKEN_KEY, storedToken)
}

export const isTokenValid = (): boolean => {
  const storedToken = getToken(false) as StoredToken

  if (storedToken) {
    const { token, expiresAt } = storedToken
    return !!token && moment().unix() < expiresAt
  }

  return false
}
