import { Icon } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import moment from 'moment'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useBookStore } from '../../../stores/books'
import { useDebugStore } from '../../../stores/debug'

import style from './header.module.css'

interface Props {
  bookId: string
  artefactId?: string
}

const shortRevision = (aid: string | undefined) => aid?.split('.')[0]

const ActiveBook: FC<Props> = (props) => {
  const [title, setTitle] = useState('')
  const navigate = useNavigate()
  const book = useBookStore(useCallback((state) => state.books[props.bookId], [props.bookId]))
  const [revisions, loadRevisions] = useDebugStore((state) => [state.revisions, state.loadRevisions])

  useEffect(() => {
    setTitle(book?.title || props.bookId || '')

    if (props.bookId) {
      loadRevisions(props.bookId)
    }
  }, [props.bookId])

  const goToRevision = (artefactId: string) => {
    navigate(`books/${props.bookId}/${artefactId}/debug`, { replace: true })
  }

  if (!props.bookId) {
    return null
  }

  return (
    <div>
      {/* middle section */}
      <div className="header-current-book">
        <span className="bookid">
          <strong>{title}</strong>
        </span>
        <span className="artifact bp4-text-small">
          {!revisions?.length ? (
            <span>{shortRevision(props.artefactId)}</span>
          ) : (
            <Popover2
              minimal
              content={
                <div style={{ padding: 10 }}>
                  <table className="bp4-html-table">
                    <thead>
                      <tr>
                        <th>Revision ID</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {revisions.map((x, idx) => (
                        <tr key={x.artifact_id}>
                          <td>
                            <a onClick={() => goToRevision(x.artifact_id)}>{shortRevision(x.artifact_id)}</a>
                          </td>
                          <td>{moment(x.created_at).format('LLL')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }
              placement="bottom-start"
              transitionDuration={0}
              portalClassName="headerMenuPortal"
              popoverClassName="headerMenuPopover"
              interactionKind="hover"
            >
              <span className={style.artefact}>
                <strong>Revision: </strong>
                {shortRevision(props.artefactId)} <Icon icon="caret-down" size={16} />
              </span>
            </Popover2>
          )}
        </span>
      </div>
    </div>
  )
}

export default ActiveBook
