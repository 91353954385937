import { Button, H2 } from '@blueprintjs/core'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { useAuth, useClient } from '../../supabase'

import TemplateDialog from './TemplateDialog'
import TemplateItem from './TemplateItem'

export interface TemplateEntry {
  id: number
  template_id: string
  title: string
  description: string
  json: string
  created_at: Date
}

const Templates = () => {
  const [showAdd, setShowAdd] = useState(false)
  const [templates, setTemplates] = useState<TemplateEntry[] | null>([])
  const [selected, setSelected] = useState<TemplateEntry | undefined>()
  const { isAdmin } = useAuth()
  const client = useClient()

  useEffect(() => {
    void loadTemplates()
  }, [])

  const loadTemplates = async () => {
    const { data } = await client.from<TemplateEntry>('templates').select()
    setTemplates(data)
  }

  const editItem = (item?: TemplateEntry) => {
    setSelected(item)
    setShowAdd(true)
  }

  return (
    <div>
      <H2>
        Templates
        {isAdmin && <Button minimal icon="plus" onClick={() => editItem(undefined)} />}
      </H2>
      <div style={{ display: 'flex', margin: '20px 0 40px 0' }}>
        {templates?.map((item) => (
          <TemplateItem key={item.template_id} item={item} isAdmin={isAdmin} onEdit={() => editItem(item)} />
        ))}
      </div>

      <TemplateDialog
        selected={selected}
        isOpen={showAdd}
        toggle={(needRefresh) => {
          setShowAdd(!showAdd)
          needRefresh && loadTemplates()
        }}
      />
    </div>
  )
}

export default Templates
