import _ from 'lodash'
import create from 'zustand'

interface State {
  location: [string, string]
  setLocation: (lastBookId: string, lastLocation: string) => void
}

export const useEditorStore = create<State>()((set) => ({
  location: ['', ''],
  setLocation: (lastBookId: string, lastLocation: string) => set({ location: [lastBookId, lastLocation] })
}))
