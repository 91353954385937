const NoTestEmptyState: React.FC<{}> = props => (
  <div className="nonIdealTab">
    <div className="bp4-non-ideal-state">
      <div className="bp4-non-ideal-state-visual">
        <span className="bp4-icon bp4-icon-pin" />
      </div>
      <h4 className="bp4-heading">There are no saved queries</h4>
      <div style={{ maxWidth: '250px' }}>Save a query to see it here.</div>
    </div>
  </div>
)

export default NoTestEmptyState
