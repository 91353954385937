import PerfectScrollbar from 'react-perfect-scrollbar'
import { useNavigate } from 'react-router-dom'

import style from './inspector.module.css'

const PALETTE = ['#68C1EE', '#62D96B', '#68C1EE', '#BDADFF', '#FF66A1', '#FF9980', '#D69FD6']

const Inspector: React.FC<{ debug_obj: any; bookId?: string; artefactId?: string }> = props => {
  const navigate = useNavigate()
  const k: any[] = props.debug_obj?.detailed?.factretreival?.o?.top_search_results

  const colors = Array(100)
    .fill('')
    .map((_, i) => PALETTE[i % PALETTE.length])

  const structure: any = {}

  if (!k) {
    return null
  }

  for (const x of k) {
    structure[x?.fact.topic] = structure[x?.fact.topic] || { __color: colors.shift() }
    structure[x?.fact.topic][x?.fact.subtopic] = structure[x?.fact.topic][x?.fact.subtopic] || []
    structure[x?.fact.topic][x?.fact.subtopic].push(x)
  }

  const goToFact = (topic: string, subtopic: string) =>
    navigate(`/books/${props.bookId}/${props.artefactId}/edit?topic=${topic}&subtopic=${subtopic}`)

  return (
    <PerfectScrollbar className={style.container}>
      {Object.keys(structure).map(topic =>
        Object.keys(structure[topic])
          .filter(x => x !== '__color')
          .map(subtopic => (
            <div key={subtopic} className={style.topic}>
              <div>
                <span className={style.circle} style={{ backgroundColor: structure[topic].__color }} />
                {topic}
              </div>
              <div className={style.subtopic}>
                <a onClick={() => goToFact(topic, subtopic)}>{subtopic}</a>
              </div>
              <div className={style.facts}>
                {structure[topic][subtopic].map((fact: any, idx: number) => (
                  <div key={idx} className={style.fact}>
                    {fact.fact.text}
                  </div>
                ))}
              </div>
            </div>
          ))
      )}
    </PerfectScrollbar>
  )
}

export default Inspector
