import { NumericInput } from '@blueprintjs/core'
import { Classes, Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { FC, useState } from 'react'

import './params.css'

interface Props {
  onChange: (value: number) => void
}

const DEFAULT_VALUE: number = 15

const TopFactPicker: FC<Props> = props => {
  const [value, setValue] = useState(DEFAULT_VALUE)
  const isMutatedCls = value === DEFAULT_VALUE ? '' : 'mutated'

  const updateValue = (val: number) => {
    setValue(Math.min(Math.max(val, 0), 30))
    props.onChange(val)
  }

  return (
    <Popover2
      minimal
      content={<NumericInput style={{ width: 60 }} value={value} onValueChange={updateValue} />}
      placement="top"
      transitionDuration={0}
    >
      <Tooltip2
        content={
          <span>
            The number of facts to retrieve from the knowledge-base for the answer generation engine.
            <br />
            Must be a value between <strong>5</strong> and <strong>30</strong>.<br />
            Higher values usually yield better responses but will increase latency.
          </span>
        }
        placement="top"
        className={Classes.TOOLTIP2_INDICATOR}
      >
        <span className={`unselectable ${isMutatedCls}`}>top-k-facts={value}</span>
      </Tooltip2>
    </Popover2>
  )
}

export default TopFactPicker
