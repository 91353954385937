import { MenuItem, Props } from '@blueprintjs/core'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useBookStore } from '../../../stores/books'
import { pageFromLocation } from '../Header/EditToggle'

import CreateBookDialog from './CreateBookDialog'
import HeaderMenu from './HeaderMenu'
import OpenBookDialog from './OpenBook'

const FileMenu: React.FC<Props> = (props) => {
  const [showCreate, setShowCreate] = useState(false)
  const [showOpen, setShowOpen] = useState(false)
  const [books, updateBook] = useBookStore((state) => [state.books, state.updateBook])
  const location = useLocation()
  const navigate = useNavigate()
  const { isDebug } = pageFromLocation(location)

  return (
    <>
      <HeaderMenu text="File">
        <MenuItem text="Open Book" icon="folder-open" onClick={() => setShowOpen(!showOpen)} labelElement="Shift+ o" />
        <MenuItem text="Create New Book" icon="document" onClick={() => setShowCreate(!showCreate)} />
        <MenuItem disabled={!Object.values(books).length} text="Switch Book" icon="folder-open" {...props}>
          {Object.values(books).map(({ title, bookId, artefactId }, idx) => (
            <MenuItem
              text={title || bookId}
              onClick={() => {
                updateBook(bookId, { lastOpened: new Date() })
                navigate(`/books/${bookId}/${artefactId}/${isDebug ? 'debug' : 'edit'}`)
              }}
              key={bookId}
            />
          ))}
        </MenuItem>
      </HeaderMenu>
      <CreateBookDialog isOpen={showCreate} toggle={() => setShowCreate(!showCreate)} />
      <OpenBookDialog isOpen={showOpen} toggle={() => setShowOpen(!showOpen)} />
    </>
  )
}

export default FileMenu
