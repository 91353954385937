import { Button, Icon, Intent } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import React, { useEffect } from 'react'

import { Turn } from '.'

import './debug.css'

const renderAttachment = (attachment: string) => {
  const parts = attachment.split(':')
  if (parts[0] === '__playground') {
    if (parts[1] === 'start_tutorial') {
      return (
        <a href="#">
          Start Tutorial <Icon icon="play" size={14} />
        </a>
      )
    }
  }
  return <span>{parts.join(':')}</span>
}
interface Props {
  scrollbarRef: React.MutableRefObject<HTMLElement | undefined>
  activeTurnId?: string
  onTurnSelected?: (turnId: string | undefined) => void
  turns?: Turn[]
  sendFeedback: (turn: Turn, feedback: number) => void
  pinQuestion: (question: string) => void
}

const History: React.FC<Props> = (props) => {
  const scrollToBottom = () => {
    if (props.scrollbarRef?.current) {
      props.scrollbarRef.current.scrollTop = 1000000
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [props.turns])

  return (
    <div className="history">
      {props.turns?.map((turn, idx) => {
        const isActive = turn.turn_id === props.activeTurnId
        const canInspect = !!turn.answer_debug
        return (
          <div key={idx} className="turn">
            <div
              className={`bubble question ${isActive ? 'active' : ''}`}
              onClick={() => canInspect && props.onTurnSelected?.(isActive ? undefined : turn.turn_id)}
            >
              {turn.user_question_text}
              <div className="feedback">
                <Tooltip2 content="Pin this question to saved queries">
                  <Button
                    className="white"
                    icon="pin"
                    onClick={(e) => {
                      e.stopPropagation()
                      props.pinQuestion(turn.user_question_text)
                    }}
                  />
                </Tooltip2>
              </div>
            </div>
            <div
              className={`bubble answer ${isActive ? 'active' : ''} ${canInspect ? 'inspect' : ''}`}
              onClick={() => canInspect && props.onTurnSelected?.(isActive ? undefined : turn.turn_id)}
            >
              {turn.answer_text}
              {turn.answer_status === 'pending' ? (
                <div style={{ padding: '5px 12px' }}>
                  <div className="dot-typing" />
                </div>
              ) : null}
              {turn.answer_debug && (
                <div className="feedback">
                  <Button
                    icon="thumbs-up"
                    className={'white ' + (turn.feedback === 5 ? 'visible' : '')}
                    disabled={turn.feedback !== undefined}
                    intent={turn.feedback === 5 ? Intent.PRIMARY : Intent.NONE}
                    onClick={(e) => {
                      e.stopPropagation()
                      props.sendFeedback(turn, 5)
                    }}
                  />
                  <Button
                    icon="thumbs-down"
                    className={'white ' + (turn.feedback === 1 ? 'visible' : '')}
                    disabled={turn.feedback !== undefined}
                    intent={turn.feedback === 1 ? Intent.PRIMARY : Intent.NONE}
                    onClick={(e) => {
                      e.stopPropagation()
                      props.sendFeedback(turn, 1)
                    }}
                  />
                </div>
              )}
            </div>
            <div className="attachments">
              {turn.answer_attachments?.length ? <div className="label">Attachments</div> : null}
              {turn.answer_attachments?.map((attachment) => (
                <div key={attachment} className="attachment-item">
                  {renderAttachment(attachment)}
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default History
