import { H5, AnchorButton, Card, Elevation, Button, InputGroup, TextArea, Intent } from '@blueprintjs/core'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Book, useBookStore } from '../../stores/books'

interface Props {
  book: Book
}

const BookCard: FC<Props> = ({ book }) => {
  const [isEditing, setEditing] = useState(false)
  const [title, setTitle] = useState(book.title)
  const [desc, setDesc] = useState(book.description)
  const [updateBook, removeBook] = useBookStore((state) => [state.updateBook, state.removeBook])
  const navigate = useNavigate()

  const update = () => {
    updateBook(book.bookId, { title, description: desc })
    setEditing(false)
  }

  return (
    <Card elevation={Elevation.ZERO} style={{ width: '400px', maxHeight: 180, marginRight: 10, position: 'relative' }}>
      {isEditing ? (
        <>
          <H5>
            <InputGroup
              placeholder="Title of your book"
              value={title}
              maxLength={80}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
          </H5>

          <TextArea
            placeholder="Description"
            cols={50}
            value={desc}
            onChange={(e) => setDesc(e.currentTarget.value)}
            maxLength={500}
          />

          <div style={{ margin: '5px 0 5px 0' }}>
            <Button icon="trash" intent={Intent.DANGER} onClick={() => removeBook(book.bookId)} />

            <span style={{ float: 'right' }}>
              <Button text="Cancel" onClick={() => setEditing(false)} style={{ marginRight: 5 }} />
              <Button text="Save" intent={Intent.PRIMARY} onClick={update} />
            </span>
          </div>
        </>
      ) : (
        <>
          <H5>
            <a onClick={() => navigate(`/books/${book.bookId}/${book.artefactId}/edit`)}>{title || book.bookId}</a>
          </H5>

          <p>{desc}</p>

          <AnchorButton
            icon="play"
            onClick={() => {
              updateBook(book.bookId, { lastOpened: new Date() })
              navigate(`/books/${book.bookId}/${book.artefactId}/debug`)
            }}
          >
            Debug
          </AnchorButton>

          <div style={{ position: 'absolute', right: 5, top: 5 }}>
            <Button minimal icon="edit" onClick={() => setEditing(!isEditing)} />
          </div>
        </>
      )}
    </Card>
  )
}

export default BookCard
