import { InputGroup, Button, Intent, Callout, ButtonGroup } from '@blueprintjs/core'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'

const sanitizeName = (text: string) =>
  text
    .replace(/\s/g, '-')
    .replace(/[^a-zA-Z0-9\/_.-]/g, '')
    .replace(/\/\//, '/')

interface Props {
  onCreate: (name: string) => void
  toggle: () => void
}

const DocumentCreate: FC<Props> = (props) => {
  const [name, setName] = useState('')

  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    setName('')
    setError(undefined)
  }, [])

  const create = () => {
    props.onCreate(name.endsWith('.md') ? name : `${name}.md`)
    props.toggle()
  }

  return (
    <div>
      <ButtonGroup>
        <Button icon="cross" onClick={() => props.toggle()} style={{ width: '30px' }} />
        <InputGroup
          autoFocus
          placeholder="Type the name of your document"
          value={name}
          // style={{ width: '280px' }}
          onChange={(e) => setName(sanitizeName(e.currentTarget.value))}
          onKeyPress={(e) => e.key === 'Enter' && create()}
        />

        <Button text="Create" intent={Intent.PRIMARY} disabled={!name || !!error} onClick={create} />
      </ButtonGroup>

      <div style={{ margin: '5px 0 5px 0' }} />
      {error !== undefined && <Callout intent={Intent.DANGER}>{error}</Callout>}
    </div>
  )
}

export default DocumentCreate
