import { Button, Divider, Icon, Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../../supabase'

import style from './header.module.css'

const AuthMenu = () => {
  const navigate = useNavigate()
  const { isAuthenticated, user, logout } = useAuth()

  if (!isAuthenticated) {
    return <Button text="Login" onClick={() => navigate('/login')} />
  }

  return (
    <div>
      <Popover2
        minimal
        placement="bottom-start"
        modifiers={{
          offset: {
            enabled: true,
            options: {
              offset: [0, 5]
            }
          }
        }}
        content={
          <>
            <div className={style.user}>
              Currently logged in as <strong>{user?.email}</strong>
            </div>
            <Divider />
            <Menu>
              <MenuItem icon="log-out" text="Log out" onClick={() => logout({ returnTo: window.location.origin })} />
            </Menu>
          </>
        }
      >
        <Icon icon="user" />
      </Popover2>
    </div>
  )
}

export default AuthMenu
