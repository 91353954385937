import { useNavigate, useLocation, useParams, Location } from 'react-router-dom'

import style from './header.module.css'

export const pageFromLocation = (location: Location) => {
  const parts = location?.pathname?.split('/')
  const isEdit = parts[parts.length - 1].toLowerCase() === 'edit'
  const isDebug = parts[parts.length - 1].toLowerCase() === 'debug'
  return { isEdit, isDebug }
}

const EditToggle = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const goto = (path: string) => navigate(`/books/${params.bookId}/${params.artefactId}/${path}`, { replace: true })
  const { isEdit, isDebug } = pageFromLocation(location)

  if (!isEdit && !isDebug) {
    return null
  }

  return (
    <div className={style.toggle}>
      <span className={`${style.option} ${isEdit ? style.active : ''}`} onClick={() => goto('edit')}>
        Edit
      </span>
      <span className={`${style.option} ${isDebug ? style.active : ''}`} onClick={() => goto('debug')}>
        Debug
      </span>
    </div>
  )
}

export default EditToggle
