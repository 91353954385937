import { H5, AnchorButton, Card, Elevation, InputGroup, Button, Intent, Callout } from '@blueprintjs/core'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useBookStore } from '../../stores/books'
import { cleanBookId } from '../../utils/misc'
import { toast } from '../../utils/toaster'

import { TemplateEntry } from './Templates'

interface Props {
  item: TemplateEntry
  isAdmin?: boolean
  onEdit: (templateId: string) => void
}

const TemplateItem: FC<Props> = ({ item, isAdmin, onEdit }) => {
  const [bookId, setBookId] = useState('')
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [books, addBook] = useBookStore((state) => [state.books, state.addBook])
  const navigate = useNavigate()

  useEffect(() => {
    setBookId('')
    setError(undefined)
  }, [isCreating])

  const getFiles = () => {
    try {
      return JSON.parse(item.json)
    } catch (error) {
      console.error('Error parsing json for template', error, item)
    }
  }

  const create = () => {
    const parsed = getFiles()

    const newBook = {
      ..._.pick(item, ['title', 'description']),
      bookId,
      files: parsed.files,
      savedQueries: [],
      artefactId: 'none'
    }

    addBook(newBook)

    toast.success('Book created successfully')
    navigate(`books/${bookId}/none/edit`, { replace: true })
  }

  const updateBookId = (newId: string) => {
    setBookId(newId)

    if (Object.keys(books).find((x) => x === newId)) {
      setError('The specified book ID already exists')
    } else {
      setError(undefined)
    }
  }

  return (
    <Card elevation={Elevation.ZERO} style={{ width: '400px', maxHeight: 180, marginRight: 10, position: 'relative' }}>
      {isAdmin && (
        <div style={{ position: 'absolute', right: 5, top: 5 }}>
          <Button minimal icon="edit" onClick={() => onEdit(item.template_id)} />
        </div>
      )}
      <H5>{item.title} </H5>
      <p>{item.description}</p>
      {isCreating ? (
        <div>
          <InputGroup
            autoFocus
            placeholder="Choose an ID for your book"
            value={bookId}
            maxLength={30}
            onChange={(e) => updateBookId(cleanBookId(e.currentTarget.value))}
            onKeyPress={(e) => e.key === 'Enter' && create()}
          />

          <div style={{ margin: '5px 0 5px 0' }}>
            <Button text="Cancel" onClick={() => setIsCreating(false)} />
            <Button text="Create" intent={Intent.PRIMARY} disabled={!bookId || !!error} onClick={create} />
          </div>
          {error !== undefined && <Callout intent={Intent.DANGER}>{error}</Callout>}
        </div>
      ) : (
        <AnchorButton text="Create from template" onClick={() => setIsCreating(true)} />
      )}
    </Card>
  )
}

export default TemplateItem
