export default {
  summary: {
    ms: 1723,
    steps: [
      '0. languagedetection => disabled (ms=0)',
      '1. spellcheck => where is the botpress office ? also , who led the Series A? (ms=73)',
      '2. translation => disabled (ms=0)',
      '3. idea => [where is the botpress office?](ask) [also, who led the Series A?](ask) (ms=30)',
      '4. querysimplification => also, who led the Series A? (ms=23)',
      '5. coreference => also, who led the Series A? (ms=74)',
      '6. entities => also, who led the Series A (Round of Financing)? (ms=98)',
      "7. factretreival => Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company) (ms=18)",
      "8. answergeneration => Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company) (ms=1403)"
    ]
  },
  detailed: {
    ms: 1723,
    languagedetection: {
      task_index: 0,
      task_id: 'languagedetection',
      state: 'disabled',
      summary: 'disabled',
      i: {
        utterance: 'Where is the botpress office? Also, who led the Series A?'
      },
      o: {
        languages: {
          en: 1
        },
        elected: 'en'
      },
      ms: 0
    },
    spellcheck: {
      task_index: 1,
      task_id: 'spellcheck',
      state: 'enabled',
      summary: 'where is the botpress office ? also , who led the Series A?',
      i: {
        utterance: 'Where is the botpress office? Also, who led the Series A?',
        lang: 'en',
        pattern_entities: [],
        list_entities: [
          {
            name: 'Venture Capital Company',
            synonyms: ['Decibel', 'Inovia', 'Panache']
          },
          {
            name: 'Softwate Company',
            synonyms: ['Botpress']
          },
          {
            name: 'Telecommunications Company',
            synonyms: ['Windstream']
          },
          {
            name: 'Non Profit Company',
            synonyms: ['Botpress for Good', 'BFG']
          },
          {
            name: 'Software Service',
            synonyms: ['GitHub', 'YouTube']
          },
          {
            name: 'Round of Financing',
            synonyms: ['Series A']
          },
          {
            name: 'Service-Level Agreement',
            synonyms: ['SLA']
          },
          {
            name: 'Software Application',
            synonyms: ['Chatbots']
          }
        ]
      },
      o: {
        corrected: 'where is the botpress office ? also , who led the Series A?'
      },
      ms: 73
    },
    translation: {
      task_index: 2,
      task_id: 'translation',
      state: 'disabled',
      summary: 'disabled',
      i: {
        utterance: 'where is the botpress office ? also , who led the Series A?',
        from_lang: 'en'
      },
      o: {
        translated: 'where is the botpress office ? also , who led the Series A?'
      },
      ms: 0
    },
    idea: {
      task_index: 3,
      task_id: 'idea',
      state: 'enabled',
      summary: '[where is the botpress office?](ask) [also, who led the Series A?](ask)',
      i: {
        utterance: 'where is the botpress office ? also , who led the Series A?'
      },
      o: {
        ideas: [
          {
            start: 0,
            stop: 30,
            confidence: 0.9972003698348999,
            text: 'where is the botpress office?',
            label: 'ask'
          },
          {
            start: 31,
            stop: 59,
            confidence: 0.8503106832504272,
            text: 'also, who led the Series A?',
            label: 'ask'
          }
        ]
      },
      ms: 30
    },
    querysimplification: {
      task_index: 4,
      task_id: 'querysimplification',
      state: 'enabled',
      summary: 'also, who led the Series A?',
      i: {
        idea_tags: [
          {
            start: 0,
            stop: 30,
            confidence: 0.9972003698348999,
            text: 'where is the botpress office?',
            label: 'ask'
          },
          {
            start: 31,
            stop: 59,
            confidence: 0.8503106832504272,
            text: 'also, who led the Series A?',
            label: 'ask'
          }
        ],
        utterance: 'where is the botpress office ? also , who led the Series A?',
        context: [
          'Chatbots',
          'Botpress',
          'Founders',
          'Definition, what is a chatbot',
          'Use case, why use a chatbot',
          'How do chatbots work?, underlying technology',
          'How do users interact, using a chatbot',
          'Offices, locations, headquarter',
          'Employment, hiring, jobs, open positions (hiring)',
          'Customers, users, adoption, social proof',
          'Social implications',
          'Founders, founding story',
          'Funding, investors and board of directors',
          'Help, Support',
          'Online Presence, Community',
          'Software, Product',
          'Co-Founders, Founders'
        ]
      },
      o: {
        all_queries: [
          {
            relevance: 0,
            main_tag: {
              start: 31,
              stop: 59,
              confidence: 0.8503106832504272,
              text: 'also, who led the Series A?',
              label: 'ask'
            },
            sub_tags: []
          },
          {
            relevance: 0,
            main_tag: {
              start: 0,
              stop: 30,
              confidence: 0.9972003698348999,
              text: 'where is the botpress office?',
              label: 'ask'
            },
            sub_tags: []
          }
        ],
        refined_utterance: 'also, who led the Series A?',
        relevance_score: 0
      },
      ms: 23
    },
    coreference: {
      task_index: 5,
      task_id: 'coreference',
      state: 'enabled',
      summary: 'also, who led the Series A?',
      i: {
        utterance: 'also, who led the Series A?',
        history: []
      },
      o: {
        clusters: [],
        augmented: 'also, who led the Series A?'
      },
      ms: 74
    },
    entities: {
      task_index: 6,
      task_id: 'entities',
      state: 'enabled',
      summary: 'also, who led the Series A (Round of Financing)?',
      i: {
        utterance: 'also, who led the Series A?',
        pattern_entities: [],
        list_entities: [
          {
            name: 'Venture Capital Company',
            synonyms: ['Decibel', 'Inovia', 'Panache']
          },
          {
            name: 'Softwate Company',
            synonyms: ['Botpress']
          },
          {
            name: 'Telecommunications Company',
            synonyms: ['Windstream']
          },
          {
            name: 'Non Profit Company',
            synonyms: ['Botpress for Good', 'BFG']
          },
          {
            name: 'Software Service',
            synonyms: ['GitHub', 'YouTube']
          },
          {
            name: 'Round of Financing',
            synonyms: ['Series A']
          },
          {
            name: 'Service-Level Agreement',
            synonyms: ['SLA']
          },
          {
            name: 'Software Application',
            synonyms: ['Chatbots']
          }
        ]
      },
      o: {
        list_entities: [
          {
            start: 18,
            stop: 26,
            type: 'Round of Financing',
            text: 'Series A',
            value: 'Series A'
          }
        ],
        pattern_entities: [],
        augmented: 'also, who led the Series A (Round of Financing)?'
      },
      ms: 98
    },
    factretreival: {
      task_index: 7,
      task_id: 'factretreival',
      state: 'enabled',
      summary:
        "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company)",
      i: {
        utterance: 'also, who led the Series A (Round of Financing)?'
      },
      o: {
        top_search_results: [
          {
            question: 'Who participated in Series A (Round of Financing)?',
            fact: {
              text: "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company)",
              topic: 'Botpress',
              topic_desc: '',
              subtopic: 'Funding, investors and board of directors',
              subtopic_desc: 'This is about funding of Botpress (money received and raised from investors).',
              precompilation_questions: []
            }
          },
          {
            question: 'Who participated in the Series A (Round of Financing) financing?',
            fact: {
              text: "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company)",
              topic: 'Botpress',
              topic_desc: '',
              subtopic: 'Funding, investors and board of directors',
              subtopic_desc: 'This is about funding of Botpress (money received and raised from investors).',
              precompilation_questions: []
            }
          },
          {
            question: 'Who participated in the Series A (Round of Financing)',
            fact: {
              text: "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Decibel (Venture Capital Company), Inovia (Venture Capital Company) and previous investors",
              topic: 'Botpress',
              topic_desc: '',
              subtopic: 'Funding, investors and board of directors',
              subtopic_desc: 'This is about funding of Botpress (money received and raised from investors).',
              precompilation_questions: []
            }
          },
          {
            question: 'Who participated in the Series A (Round of Financing) financing',
            fact: {
              text: "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Decibel (Venture Capital Company), Inovia (Venture Capital Company) and previous investors",
              topic: 'Botpress',
              topic_desc: '',
              subtopic: 'Funding, investors and board of directors2',
              subtopic_desc: 'This is about funding of Botpress (money received and raised from investors).',
              precompilation_questions: []
            }
          },
          {
            question: 'What does Series A (Round of Financing) stand for?',
            fact: {
              text: "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company)",
              topic: 'Botpress2',
              topic_desc: '',
              subtopic: 'Funding, investors and board of directors3',
              subtopic_desc: 'This is about funding of Botpress (money received and raised from investors).',
              precompilation_questions: []
            }
          }
        ]
      },
      ms: 18
    },
    answergeneration: {
      task_index: 8,
      task_id: 'answergeneration',
      state: 'enabled',
      summary:
        "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company)",
      i: {
        facts: [
          {
            text: "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company)",
            topic: 'Botpress',
            topic_desc: '',
            subtopic: 'Funding, investors and board of directors',
            subtopic_desc: 'This is about funding of Botpress (money received and raised from investors).',
            precompilation_questions: []
          },
          {
            text: "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company)",
            topic: 'Botpress',
            topic_desc: '',
            subtopic: 'Funding, investors and board of directors',
            subtopic_desc: 'This is about funding of Botpress (money received and raised from investors).',
            precompilation_questions: []
          },
          {
            text: "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Decibel (Venture Capital Company), Inovia (Venture Capital Company) and previous investors",
            topic: 'Botpress',
            topic_desc: '',
            subtopic: 'Funding, investors and board of directors',
            subtopic_desc: 'This is about funding of Botpress (money received and raised from investors).',
            precompilation_questions: []
          },
          {
            text: "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Decibel (Venture Capital Company), Inovia (Venture Capital Company) and previous investors",
            topic: 'Botpress',
            topic_desc: '',
            subtopic: 'Funding, investors and board of directors',
            subtopic_desc: 'This is about funding of Botpress (money received and raised from investors).',
            precompilation_questions: []
          },
          {
            text: "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company)",
            topic: 'Botpress',
            topic_desc: '',
            subtopic: 'Funding, investors and board of directors',
            subtopic_desc: 'This is about funding of Botpress (money received and raised from investors).',
            precompilation_questions: []
          }
        ],
        utterance: 'also, who led the Series A (Round of Financing)?',
        answer_level: 'medium'
      },
      o: {
        answer:
          "Botpress (Softwate Company)' Series A (Round of Financing) had participation from Magaly Charboneau from Inovia (Venture Capital Company)"
      },
      ms: 1403
    }
  }
}
