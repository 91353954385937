import { Button, Callout, FormGroup, InputGroup, Intent, TextArea } from '@blueprintjs/core'
import { FC, useEffect, useState } from 'react'

import { DialogBody, DialogFooter, DialogWrapper } from '../../components/Dialog'
import { useClient } from '../../supabase'
import { cleanBookId } from '../../utils/misc'

import { TemplateEntry } from './Templates'

interface Props {
  isOpen: boolean
  toggle: (needRefresh?: boolean) => void
  selected?: TemplateEntry
}

const TemplateDialog: FC<Props> = (props) => {
  const [templateId, setTemplateId] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [json, setJson] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)
  const client = useClient()

  useEffect(() => {
    const { template_id, title, description, json } = props.selected || {}

    setTemplateId(template_id || '')
    setTitle(title || '')
    setDescription(description || '')
    setJson((json as any) || '')
    setError(undefined)
  }, [props.isOpen, props.selected])

  const isEditing = props.selected !== undefined
  const id = props.selected?.id

  const save = async () => {
    try {
      JSON.parse(json)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
      return
    }

    if (isEditing) {
      await client.from('templates').update({ template_id: templateId, title, description, json }).eq('id', id)
    } else {
      await client.from('templates').insert({ template_id: templateId, title, description, json })
    }

    props.toggle(true)
  }

  const remove = async () => {
    if (confirm('Are you sure you want to delete it?')) {
      await client.from('templates').delete().match({ id })
      props.toggle(true)
    }
  }

  return (
    <DialogWrapper
      isOpen={props.isOpen}
      onClose={() => props.toggle()}
      title={isEditing ? 'Edit template' : 'Create a new template'}
    >
      <DialogBody>
        <FormGroup label="Template ID">
          <InputGroup
            autoFocus
            value={templateId}
            onChange={(e) => setTemplateId(cleanBookId(e.currentTarget.value))}
            maxLength={30}
          />
        </FormGroup>

        <FormGroup label="Title">
          <InputGroup value={title} onChange={(e) => setTitle(e.currentTarget.value)} maxLength={80} />
        </FormGroup>

        <FormGroup label="Description">
          <InputGroup value={description} onChange={(e) => setDescription(e.currentTarget.value)} maxLength={500} />
        </FormGroup>

        <FormGroup label="JSON Payload *">
          <TextArea rows={5} value={json} fill onChange={(e) => setJson(e.currentTarget.value)} />
        </FormGroup>

        {error !== undefined && <Callout intent={Intent.DANGER}>{error}</Callout>}
      </DialogBody>
      <DialogFooter>
        {isEditing && <Button text="Delete" intent={Intent.DANGER} onClick={remove} />}
        <Button text="Cancel" onClick={() => props.toggle()} />
        <Button text="Save" intent={Intent.PRIMARY} onClick={save} disabled={!templateId || !json} />
      </DialogFooter>
    </DialogWrapper>
  )
}

export default TemplateDialog
