const serialize = <T>(value: T): string => {
  if (value === null || value === undefined) {
    throw new Error('[Storage] Cannot store null or undefined values')
  }

  if (typeof value === 'string') {
    return value
  }

  try {
    return JSON.stringify(value)
  } catch {
    console.error('[Storage] Error parsing value', value)
    return ''
  }
}

const deserialize = <T>(strValue: string | null | undefined): T | undefined => {
  if (strValue === null || strValue === undefined) {
    return undefined
  }

  try {
    return JSON.parse(strValue)
  } catch {
    return strValue as any
  }
}

const storage = {
  set: <T>(key: string, value: T) => {
    try {
      localStorage.setItem(key, serialize(value))
    } catch (err) {
      console.error('Error while setting data into storage.', err)
    }
  },
  get: <T = string>(key: string): T | undefined => {
    try {
      return deserialize(localStorage.getItem(key))
    } catch (err) {
      console.error('Error while getting data from storage.', err)
    }
  },
  del: (key: string) => {
    try {
      localStorage.removeItem(key)
    } catch (err) {
      console.error('Error while deleting data from storage.', err)
    }
  }
}

export default storage
