import { NumericInput } from '@blueprintjs/core'
import { Classes, Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { FC, useState } from 'react'

import './params.css'

interface Props {
  onChange: (value: number) => void
}

const DEFAULT_VALUE: number = 0

const HistoryLengthPicker: FC<Props> = props => {
  const [value, setValue] = useState(DEFAULT_VALUE)
  const isMutatedCls = value === DEFAULT_VALUE ? '' : 'mutated'

  const updateValue = (val: number) => {
    setValue(Math.min(Math.max(val, 0), 5))
    props.onChange(val)
  }

  return (
    <Popover2
      minimal
      content={<NumericInput style={{ width: 60 }} value={value} onValueChange={updateValue} />}
      placement="top"
      transitionDuration={0}
    >
      <Tooltip2
        content={
          <span>
            Number of previous user messages to include in the query as context.
            <br />
            Set to <strong>0</strong> to disable contextual responses.
          </span>
        }
        placement="top"
        className={Classes.TOOLTIP2_INDICATOR}
      >
        <span className={`unselectable ${isMutatedCls}`}>history-length={value}</span>
      </Tooltip2>
    </Popover2>
  )
}

export default HistoryLengthPicker
