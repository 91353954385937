import { AuthChangeEvent, Session, User } from '@supabase/supabase-js'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useClient from './useClient'

interface LogoutParams {
  returnTo?: string
}

interface Auth {
  isAuthenticated: boolean
  /** User is authorized to access openbook */
  isAuthorized: boolean
  /** Shows the various admin buttons on the UI.  */
  isAdmin?: boolean
  user?: User | null
  logout: (options?: LogoutParams) => void
}

const useAuth = (): Auth => {
  const client = useClient()
  // eslint-disable-next-line eqeqeq
  const [isAuthenticated, setAuthenticated] = useState(client.auth.user() != undefined)
  const [isAuthorized, setAuthorized] = useState(false)
  const [isAdmin, setAdmin] = useState(false)
  const [user, setUser] = useState<User | undefined | null>(client.auth.user())
  const [session, setSession] = useState<Session | undefined | null>(client.auth.session())
  const navigate = useNavigate()

  useEffect(() => {
    const user = client.auth.user()

    setUser(user)
    setSession(client.auth.session())
    setAuthenticated(user !== undefined && user !== null)
    setAdmin(user?.email?.endsWith('@botpress.com') || false)

    const { data: authListener } = client.auth.onAuthStateChange((ev, sess) => {
      if (ev === 'SIGNED_OUT') {
        setAuthenticated(false)
      } else if (ev === 'SIGNED_IN') {
        setAuthenticated(true)
      }

      setUser(client.auth.user())
    })
    return () => {
      authListener?.unsubscribe()
    }
  }, [])

  useEffect(() => {
    setAuthorized(user?.app_metadata?.authorized)
    setSession(client.auth.session())
  }, [user])

  const logout = async () => {
    await client.auth.signOut()
    navigate('/login')
  }

  return { isAuthenticated, isAuthorized, isAdmin, user, logout }
}

export default useAuth
