import Editor from '@monaco-editor/react'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import markdownParser from 'prettier/parser-markdown'
import prettier from 'prettier/standalone'
import { FC, useEffect, useState } from 'react'

import { BookFile } from '../../../stores/books'

interface Props {
  currentFile?: BookFile
  onSave: (fileContent: string, filePath: string) => void
  revealLine: number
  bookId?: string
}

export type FileWithMetadata = BookFile & {
  uri: monaco.Uri
  state?: any
  hasChanges?: boolean
}

const App: FC<Props> = (props) => {
  const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor>()
  const [openedFiles, setOpenedFiles] = useState<FileWithMetadata[]>([])
  const [currentFile, setCurrentFile] = useState<FileWithMetadata | undefined>()

  useEffect(() => {
    if (!props.currentFile) {
      return
    }

    const { location } = props.currentFile
    const fileAlreadyOpened = openedFiles.find((x) => x.location === location)
    if (fileAlreadyOpened) {
      return setCurrentFile(fileAlreadyOpened)
    }

    const file: FileWithMetadata = {
      ...props.currentFile,
      location,
      hasChanges: false,
      uri: monaco.Uri.parse(`bp:/${location}`)
    }

    if (file) {
      setOpenedFiles([...openedFiles, file])
      setCurrentFile(file)
    }
  }, [props.currentFile])

  useEffect(() => {
    monaco.editor.getModels().forEach((model) => model.dispose())
    setOpenedFiles([])
  }, [props.bookId])

  useEffect(() => {
    if (props.revealLine) {
      editor?.revealLineInCenter(props.revealLine)
    }
  }, [props.currentFile, editor])

  const handleEditorDidMount = (editor: monaco.editor.IStandaloneCodeEditor, monaco: any) => {
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, async () => {
      await editor.getAction('editor.action.formatDocument').run()

      const path = editor.getModel()?.uri.path.replace('/', '') || ''
      props.onSave(editor.getValue(), path)
    })

    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyP, () =>
      editor.trigger('', 'editor.action.quickCommand', '')
    )

    monaco.languages.registerDocumentFormattingEditProvider('markdown', {
      async provideDocumentFormattingEdits(model: any) {
        const text = prettier.format(model.getValue(), {
          parser: 'markdown',
          plugins: [markdownParser],
          singleQuote: true,
          printWidth: 120,
          trailingComma: 'none',
          semi: false,
          bracketSpacing: true,
          requirePragma: false
        })

        return [
          {
            range: model.getFullModelRange(),
            text
          }
        ]
      }
    })

    setEditor(editor)
  }

  // const closeFile = async (uri?: monaco.Uri) => {
  //   if (!uri) {
  //     uri = currentFile?.uri
  //   }

  //   const file = openedFiles.find(x => x.uri === uri)
  //   // if (file?.hasChanges) {
  //   //   console.log('has changes')
  //   //   if (
  //   //  confirm('Do you want to save changes before?')
  //   //   ) {
  //   //     //await this.saveChanges(uri)
  //   //   }
  //   // }

  //   const model = monaco.editor.getModel(uri!)

  //   if (model) {
  //     model.dispose()
  //   }

  //   setOpenedFiles(openedFiles.filter(x => x.uri !== uri))

  //   if (openedFiles.length) {
  //     setCurrentFile(openedFiles[0])
  //   }
  // }

  const onChange = (value: string | undefined) => {
    if (currentFile) {
      props.onSave(value || '', currentFile.location)
    }
  }

  const file = openedFiles.find((x) => x.uri === currentFile?.uri)
  const language = file?.location?.endsWith('.json') ? 'json' : 'markdown'

  if (!openedFiles?.length) {
    return <div style={{ textAlign: 'center', marginTop: 50 }}>No file opened</div>
  }

  return (
    <div className="editor">
      <Editor
        height="90vh"
        path={file?.location}
        defaultLanguage={language}
        defaultValue={file?.content}
        onChange={onChange}
        onMount={handleEditorDidMount}
      />
    </div>
  )
}

export default App
