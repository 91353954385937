import { MenuDivider, MenuItem } from '@blueprintjs/core'
import { AxiosError } from 'axios'
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { GlobalHotKeys, configure } from 'react-hotkeys'
import ResizePanel from 'react-resize-panel'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import api from '../../../api'
import { BookFile, useBookStore } from '../../../stores/books'
import { useEditorStore } from '../../../stores/editor'
import { toast } from '../../../utils/toaster'
import FileMenu from '../../App/Menus/FileMenu'
import HeaderMenu from '../../App/Menus/HeaderMenu'

import DocumentCreate from './DocumentCreate'
import Editor from './Editor'
import TreeView from './TreeView'

import './editor.css'

const findFileAndLine = (files: BookFile[], search: any) => {
  const params = new URLSearchParams(search)
  const topic = params.get('topic')
  const subtopic = params.get('subtopic')
  const [, defaultFile] = useEditorStore.getState().location

  if (!topic || !subtopic) {
    return { file: defaultFile, line: 0 }
  }

  const file = files.find((x) => x.content.includes(topic) && x.content.includes(subtopic))
  if (file) {
    const line = file.content.split('\n').findIndex((x) => x.includes(subtopic)) || 0
    return { file, line }
  }

  return { file: defaultFile, line: 0 }
}

const Page = () => {
  const [files, setFiles] = useState<BookFile[]>()
  const [currentFile, setCurrentFile] = useState<any>('')
  const [specificLine, setSpecificLine] = useState(0)
  const [showNewDocument, setShowNewDocument] = useState(false)
  const [getFiles, setBookFiles, addBookFile, updateBook] = useBookStore((state) => [
    state.getFiles,
    state.setFiles,
    state.addFile,
    state.updateBook
  ])
  const setLocation = useEditorStore((state) => state.setLocation)

  const { bookId, artefactId } = useParams()
  const { search } = useLocation()
  const navigate = useNavigate()

  if (!bookId) {
    return null
  }

  useEffect(() => {
    const files = reloadFiles()

    const { file, line } = findFileAndLine(files, search)

    setSpecificLine(line)
    setCurrentFile(file)
  }, [])

  useEffect(() => {
    setLocation(bookId, currentFile)
  }, [currentFile])

  useEffect(() => {
    setCurrentFile(undefined)
  }, [bookId])

  const reloadFiles = () => {
    const files = getFiles(bookId).sort((a, b) => a.location.localeCompare(b.location))
    setFiles(files)
    return files
  }

  const onSave = (fileContent: string, filePath: string) => {
    const files = getFiles(bookId)
    const existing = files.find((x) => x.location === filePath)

    if (existing) {
      existing.content = fileContent
      setBookFiles(bookId, files)
    } else {
      addBookFile(bookId, { location: filePath, content: fileContent })
    }
  }

  const openSelectedFile = (fileName: string) => {
    const file = getFiles(bookId).find((x) => x.location === fileName)
    setCurrentFile(file)
  }

  const publishArchive = async (content: Blob) => {
    try {
      const response = await api.buildBook(bookId, content)
      updateBook(bookId, { artefactId: response.artifact_id })
      navigate(`/books/${bookId}/${response.artifact_id}/edit`, { replace: true })
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.failure(
          `Error while building book: ${
            err.response?.data?.error?.message || err.response?.data?.message || err.message
          }`
        )
      }
    }
  }

  const makeArchive = (download?: boolean) => {
    const zip = new JSZip()

    for (const file of getFiles(bookId)) {
      zip.file(file.location, file.content)
    }

    void zip.generateAsync({ type: 'blob' }).then(async (content) => {
      if (download) {
        FileSaver.saveAs(content, 'archive.zip')
      } else {
        await publishArchive(content)
      }
    })
  }

  const createDocument = (name: string) => {
    addBookFile(bookId, { location: name, content: '' })
    reloadFiles()
    openSelectedFile(name)
  }

  const exportAsTemplate = () => {
    // const files = store.getBookFiles(bookId)
    // const bookInfo = books.find(x => x.bookId === bookId)
    // const payload = { ..._.omit(bookInfo, ['lastOpened', 'artefactId']), files }
    // FileSaver.saveAs(new Blob([JSON.stringify(payload, undefined, 2)]), `template_${bookId}.json`)
  }
  const goToDebug = () => navigate(`/books/${bookId}/${artefactId}/debug`, { replace: true })

  const keyHandlers = {
    publish: () => makeArchive(),
    goToDebug,
    addDocument: () => {
      setTimeout(() => {
        setShowNewDocument(!showNewDocument)
      }, 200)
    }
  }

  const keyMap = { publish: 'ctrl+shift+s', goToDebug: ['ctrl+.', 'cmd+.', 'command+.'], addDocument: 'shift+n' }

  return (
    <GlobalHotKeys handlers={keyHandlers} keyMap={keyMap}>
      <ResizePanel direction="e" handleClass="customHandle" borderClass="customResizeBorder" style={{ flexGrow: '1' }}>
        <div className="editorSidebar">
          {showNewDocument && (
            <div style={{ padding: 10 }}>
              <DocumentCreate onCreate={createDocument} toggle={() => setShowNewDocument(!showNewDocument)} />
            </div>
          )}
          <TreeView
            files={files || []}
            currentFile={currentFile}
            onClick={(selectedFile) => openSelectedFile(selectedFile)}
          />
        </div>
      </ResizePanel>
      <div className="content">
        <Editor onSave={onSave} currentFile={currentFile} revealLine={specificLine} bookId={bookId} />
      </div>
      <FileMenu />
      <HeaderMenu text="Book">
        <MenuItem
          text="Add Document"
          icon="plus"
          onClick={() => setShowNewDocument(!showNewDocument)}
          labelElement="Shift + N"
        />
        <MenuItem
          text={
            <span>
              Switch to <strong>Debug Mode</strong>
            </span>
          }
          icon="play"
          onClick={goToDebug}
          labelElement="⌘ + ."
        />
        <MenuDivider />
        <MenuItem text="Export as Archive" icon="archive" onClick={() => makeArchive(true)} />
        <MenuItem text="Export as Template" icon="new-text-box" onClick={() => exportAsTemplate()} />
        <MenuDivider />
        <MenuItem text={<b>Publish</b>} icon="git-push" onClick={() => makeArchive()} labelElement="⌘ + Shift + S" />
      </HeaderMenu>
    </GlobalHotKeys>
  )
}

export default Page
