import { Intent, ProgressBar } from '@blueprintjs/core'
import { FC, useEffect, useState } from 'react'

import api from '../../../api'
import { useDebugStore } from '../../../stores/debug'
import { toast } from '../../../utils/toaster'

interface Props {
  bookId?: string
  artefactId?: string
}

const BookProgress: FC<Props> = ({ bookId, artefactId }) => {
  const [progress, setProgress] = useState(0)
  const [isWorking, setWorking] = useState<boolean>(false)
  const [error, setError] = useState<any>()
  const [updateRevision] = useDebugStore((state) => [state.updateRevision])

  useEffect(() => {
    if (bookId && artefactId && artefactId !== 'none') {
      void checkProgress()
    }
  }, [bookId, artefactId])

  const checkProgress = async () => {
    const build = await api.getArtifactState(bookId!, artefactId!)
    updateRevision(artefactId!, build.state.status)

    if (build.state.status === 'DONE') {
      setWorking(false)
      return
    }

    if (isWorking) {
      return
    }

    setWorking(true)
    setError(undefined)
    setProgress(0)

    const interval = setInterval(async () => {
      const build = await api.getArtifactState(bookId!, artefactId!)
      updateRevision(artefactId!, build.state.status)
      if (build?.state.status !== 'DONE') {
        setProgress(build.state.progress)
        setError(build.state.err)
      } else {
        clearInterval(interval)
        setWorking(false)

        toast.success(`Revision ${artefactId} built successfully!`)
      }
    }, 2000)
  }

  if (!isWorking || !bookId || !artefactId) {
    return null
  }

  return (
    <ProgressBar
      intent={error ? Intent.DANGER : Intent.SUCCESS}
      value={progress}
      stripes={false}
      animate={false}
      className="usage-meter"
    />
  )
}

export default BookProgress
