import { Button, FormGroup, InputGroup, Intent, FileInput, Callout } from '@blueprintjs/core'
import { AxiosError } from 'axios'
import JSZip from 'jszip'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '../../../api'
import { DialogBody, DialogFooter, DialogWrapper } from '../../../components/Dialog'
import { BookFile, useBookStore } from '../../../stores/books'
import { cleanBookId } from '../../../utils/misc'
import { toast } from '../../../utils/toaster'

interface Props {
  isOpen: boolean
  toggle: () => void
}

const CreateBookDialog: FC<Props> = (props) => {
  const [bookId, setBookId] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [file, setFile] = useState<any>()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [books, addBook] = useBookStore((state) => [state.books, state.addBook])
  const navigate = useNavigate()

  useEffect(() => {
    setBookId('')
    setTitle('')
    setDescription('')
    setError(undefined)
    setFile(undefined)
  }, [props.isOpen])

  const createBookFromArchive = async () => {
    setLoading(true)

    try {
      let artefactId = 'none'
      if (!file) {
        const files = [{ location: 'entities.json', content: '{}' }]
        addBook({ bookId, title, description, artefactId: 'none', files })
      } else {
        const response = await api.buildBook(bookId, file)
        if (!response.success) {
          toast.failure('Error creating book; invalid archive')
          return
        }

        artefactId = response.artifact_id
        const files = await getArchiveFiles(bookId)

        addBook({ bookId, title, description, artefactId, files })
      }

      toast.info('Book created successfully')
      props.toggle()
      navigate(`/books/${bookId}/${artefactId}/edit`, { replace: true })
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.failure(
          `Error while building book: ${
            err.response?.data?.error?.message || err.response?.data?.message || err.message
          }`
        )
      }
    } finally {
      setLoading(false)
    }
  }

  const updateBookId = (newId: string) => {
    setBookId(newId)

    if (Object.keys(books).find((x) => x === newId)) {
      setError('The specified book ID already exists')
    } else {
      setError(undefined)
    }
  }

  const getArchiveFiles = async (bookId: string) => {
    const files: BookFile[] = []
    const zip = new JSZip()
    const content = await zip.loadAsync(file)

    for (const file in content.files) {
      files.push({
        location: file,
        content: (await content.file(file)?.async('string')) || ''
      })
    }

    return files
  }

  const handleFileUpload = async (event: any) => {
    if (!event.target.files) {
      return
    }

    setFile(event.target.files[0])
  }

  return (
    <>
      <DialogWrapper isOpen={props.isOpen} onClose={props.toggle} title="Create a new book">
        <DialogBody>
          <FormGroup label="Book ID *">
            <InputGroup
              autoFocus
              value={bookId}
              onChange={(e) => updateBookId(cleanBookId(e.currentTarget.value))}
              maxLength={30}
            />
          </FormGroup>

          <FormGroup label="Title">
            <InputGroup value={title} onChange={(e) => setTitle(e.currentTarget.value)} maxLength={80} />
          </FormGroup>

          <FormGroup label="Description">
            <InputGroup value={description} onChange={(e) => setDescription(e.currentTarget.value)} maxLength={500} />
          </FormGroup>

          <FormGroup
            label="Book Archive"
            helperText="Optional. Extract files from an existing book archive. Otherwise creates an empty book"
          >
            <FileInput text={file?.name || 'Choose file...'} onInputChange={handleFileUpload} />
          </FormGroup>

          {error !== undefined && <Callout intent={Intent.DANGER}>{error}</Callout>}
        </DialogBody>
        <DialogFooter>
          <Button text="Cancel" onClick={props.toggle} />
          <Button
            text="Create Book"
            intent={Intent.PRIMARY}
            onClick={createBookFromArchive}
            disabled={!bookId || !!error || isLoading}
          />
        </DialogFooter>
      </DialogWrapper>
    </>
  )
}

export default CreateBookDialog
