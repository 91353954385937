import { useEffect, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import { useNavigate } from 'react-router-dom'

import { useBookStore } from '../../../stores/books'

import CreateBookDialog from './CreateBookDialog'
import OpenBookDialog from './OpenBook'

const FileHotkeys = () => {
  const [keys, setKeys] = useState<any>()
  const [showCreate, setShowCreate] = useState(false)
  const [showOpen, setShowOpen] = useState(false)
  const navigate = useNavigate()
  const [books, updateBook] = useBookStore((state) => [state.books, state.updateBook])

  const createBook = () => {
    // Prevents the shortcut from filling the first field
    setTimeout(() => {
      setShowCreate(!showCreate)
    }, 20)
  }
  const openBook = (bookId: string, artefactId?: string) => {
    const isDebugging = window.location.href.endsWith('debug')
    updateBook(bookId, { lastOpened: new Date() })

    navigate(`/books/${bookId}/${artefactId}/${isDebugging ? 'debug' : 'edit'}`)
  }

  const openBookFinder = () => {
    setTimeout(() => {
      setShowOpen(!showOpen)
    }, 20)
  }

  useEffect(() => {
    const keyHandler = {
      ...Object.values(books).reduce(
        (acc, curr, idx) => ({ ...acc, [`openBook${idx + 1}`]: () => openBook(curr.bookId, curr.artefactId) }),
        {}
      ),
      createBook,
      openBookFinder
    }
    const keyMap = {
      ...Object.values(books).reduce((acc, curr, idx) => ({ ...acc, [`openBook${idx + 1}`]: `b+${idx + 1}` }), {}),
      createBook: 'ctrl+b',
      openBookFinder: 'shift+o'
    }

    setKeys({ keyHandler, keyMap })
  }, [])

  if (!keys) {
    return null
  }

  return (
    <div>
      <GlobalHotKeys handlers={keys.keyHandler} keyMap={keys.keyMap} />
      <CreateBookDialog isOpen={showCreate} toggle={() => setShowCreate(!showCreate)} />
      <OpenBookDialog isOpen={showOpen} toggle={() => setShowOpen(!showOpen)} />
    </div>
  )
}

export default FileHotkeys
