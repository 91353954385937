import { Button } from '@blueprintjs/core'
import { AxiosError } from 'axios'
import JSZip from 'jszip'
import _ from 'lodash'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import api from '../../../api'
import { useBookStore } from '../../../stores/books'
import { isCompilingRevision, useDebugStore } from '../../../stores/debug'
import { toast } from '../../../utils/toaster'

import { pageFromLocation } from './EditToggle'
import style from './header.module.css'

const PublishButton = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const bookId = params.bookId!
  const { isEdit } = pageFromLocation(location)

  const [getFiles, updateBook] = useBookStore((state) => [state.getFiles, state.updateBook])
  const isCompiling = useDebugStore((state) => isCompilingRevision(state.revisions, params.artefactId))

  const publishArchive = async (content: Blob) => {
    try {
      const response = await api.buildBook(bookId, content)
      updateBook(bookId, { artefactId: response.artifact_id })
      navigate(`/books/${bookId}/${response.artifact_id}/edit`, { replace: true })
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.failure(
          `Error while building book: ${
            err.response?.data?.error?.message || err.response?.data?.message || err.message
          }`
        )
      }
    }
  }

  const makeArchive = () => {
    const zip = new JSZip()

    for (const file of getFiles(bookId)) {
      zip.file(file.location, file.content)
    }

    void zip.generateAsync({ type: 'blob' }).then((content) => {
      return publishArchive(content)
    })
  }

  if (!isEdit) {
    return null
  }

  return (
    <div className={style.toggle}>
      <Button
        text={!isCompiling ? 'Publish' : 'Publishing...'}
        minimal
        onClick={() => makeArchive()}
        disabled={isCompiling}
      />
    </div>
  )
}

export default PublishButton
