import _ from 'lodash'
import React, { useState } from 'react'
import { configure } from 'react-hotkeys'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { useAuth } from '../../supabase'
import NoAccess from '../Login/NoAccess'

import ActiveBook from './Header/ActiveBook'
import AuthMenu from './Header/AuthMenu'
import EditToggle from './Header/EditToggle'
import PublishButton from './Header/PublishButton'
import FileHotkeys from './Menus/FileHotkeys'
import StatusBar from './StatusBar'
import UserLoader from './UserLoader'

configure({
  ignoreTags: []
})

const App: React.FC = () => {
  const [isLoaded, setLoaded] = useState(false)
  const { isAuthenticated, isAuthorized } = useAuth()
  const params = useParams()
  const navigate = useNavigate()

  return (
    <div className={'container'}>
      <div className={'header'}>
        <div className="header-section-left">
          <div className="header-logo">
            <span>
              <a onClick={() => navigate('/')} style={{ color: '#000' }}>
                <strong>OpenBook</strong> Playground
              </a>
            </span>
          </div>
          <div id="header-menu-root" className="header-menu" />
        </div>
        <ActiveBook bookId={params.bookId!} artefactId={params.artefactId} />
        <div className="header-section-right">
          <AuthMenu />
          {/* <Divider style={{ height: '20px' }} /> */}
          <EditToggle />
          <PublishButton />
        </div>
      </div>

      <div className={'body'}>
        {isAuthenticated && isLoaded && !isAuthorized ? (
          <NoAccess />
        ) : !isAuthenticated || isLoaded ? (
          <Outlet />
        ) : (
          <div className="content" style={{ textAlign: 'center', padding: 50 }}>
            Loading, please wait..
          </div>
        )}
      </div>
      <FileHotkeys />
      <StatusBar />
      <UserLoader setLoaded={setLoaded} />
    </div>
  )
}

export default App
