import { Classes, Tree, TreeNodeInfo } from '@blueprintjs/core'
import { cloneDeep } from 'lodash'
import React, { FC, useCallback, useEffect, useReducer, useState } from 'react'

import { BookFile } from '../../../stores/books'

type NodePath = number[]

type TreeAction =
  | { type: 'SET_IS_EXPANDED'; payload: { path: NodePath; isExpanded: boolean } }
  | { type: 'DESELECT_ALL' }
  | { type: 'SET_IS_SELECTED'; payload: { path: NodePath; isSelected: boolean } }
  | { type: 'UPDATE_NODES'; payload: { files: BookFile[]; selectedFile: BookFile } }

function forEachNode(nodes: TreeNodeInfo[] | undefined, callback: (node: TreeNodeInfo) => void) {
  if (nodes === undefined) {
    return
  }

  for (const node of nodes) {
    callback(node)
    forEachNode(node.childNodes, callback)
  }
}

function forNodeAtPath(nodes: TreeNodeInfo[], path: NodePath, callback: (node: TreeNodeInfo) => void) {
  callback(Tree.nodeFromPath(path, nodes))
}

const treeReducer = (state: TreeNodeInfo[], action: TreeAction) => {
  switch (action.type) {
    case 'DESELECT_ALL':
      const newState1 = cloneDeep(state)
      forEachNode(newState1, (node) => (node.isSelected = false))
      return newState1
    case 'SET_IS_EXPANDED':
      const newState2 = cloneDeep(state)
      forNodeAtPath(newState2, action.payload.path, (node) => (node.isExpanded = action.payload.isExpanded))
      return newState2
    case 'SET_IS_SELECTED':
      const newState3 = cloneDeep(state)
      forNodeAtPath(newState3, action.payload.path, (node) => (node.isSelected = action.payload.isSelected))
      return newState3
    case 'UPDATE_NODES':
      return action.payload.files.map((x, idx) => ({
        id: idx,
        icon: 'document' as any,
        label: x.location,
        isSelected: action.payload.selectedFile?.location === x.location
      }))
    default:
      return state
  }
}

interface Props {
  onClick: (fileName: string) => void
  files: BookFile[]
  currentFile: BookFile
}

export const TreeView: FC<Props> = (props) => {
  const [nodes, dispatch] = useReducer(treeReducer, [])

  useEffect(() => {
    dispatch({
      payload: { files: props.files, selectedFile: props.currentFile },
      type: 'UPDATE_NODES'
    })
  }, [props.files, props.currentFile])

  const handleNodeClick = useCallback((node: TreeNodeInfo, nodePath: NodePath, e: React.MouseEvent<HTMLElement>) => {
    const originallySelected = node.isSelected
    if (!e.shiftKey) {
      dispatch({ type: 'DESELECT_ALL' })
    }
    dispatch({
      payload: { path: nodePath, isSelected: originallySelected == null ? true : !originallySelected },
      type: 'SET_IS_SELECTED'
    })

    props.onClick(node.label as string)
  }, [])

  const handleNodeCollapse = useCallback((_node: TreeNodeInfo, nodePath: NodePath) => {
    dispatch({ payload: { path: nodePath, isExpanded: false }, type: 'SET_IS_EXPANDED' })
  }, [])

  const handleNodeExpand = useCallback((_node: TreeNodeInfo, nodePath: NodePath) => {
    dispatch({ payload: { path: nodePath, isExpanded: true }, type: 'SET_IS_EXPANDED' })
  }, [])

  return (
    <Tree
      contents={nodes}
      onNodeClick={handleNodeClick}
      onNodeCollapse={handleNodeCollapse}
      onNodeExpand={handleNodeExpand}
      className={''}
    />
  )
}

export default TreeView
