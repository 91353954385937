import { IconName, Intent, IToasterProps, Position, Toaster } from '@blueprintjs/core'

export interface ToastOptions {
  // Delaying to avoid the react lifecycle issue (executing before it is rendered)
  delayed?: boolean
  timeout?: 'short' | 'medium' | 'long'
  icon?: IconName | JSX.Element
  key?: string
  toasterProps?: IToasterProps
}

const defaultToaster = Toaster.create({ position: Position.BOTTOM })

const successOptions = {
  delayed: false,
  timeout: 'medium',
  icon: 'tick-circle',
  key: 'success'
}

const failureOptions = {
  delayed: true,
  timeout: 'medium',
  icon: 'error',
  key: 'error'
}

const infoOptions = {
  delayed: false,
  timeout: 'medium',
  icon: 'info-sign',
  key: 'info'
}

const warningOptions = {
  delayed: false,
  timeout: 'medium',
  icon: 'warning-sign',
  key: 'warning'
}

function selectToaster(options?: IToasterProps) {
  if (options) {
    return Toaster.create(options)
  } else {
    return defaultToaster
  }
}

const success = (message: string | React.ReactElement, details?: string, options?: ToastOptions) =>
  showToast(message, Intent.SUCCESS, Object.assign(successOptions, options))

const failure = (message: string | React.ReactElement, details?: string, options?: ToastOptions) => {
  showToast(message, Intent.DANGER, Object.assign(failureOptions, options))
}

const info = (message: string | React.ReactElement, details?: string, options?: ToastOptions) =>
  showToast(message, Intent.PRIMARY, Object.assign(infoOptions, options))

const warning = (message: string | React.ReactElement, details?: string, options?: ToastOptions) =>
  showToast(message, Intent.WARNING, Object.assign(warningOptions, options))

const showToast = (message: string | React.ReactElement, intent: any, options: ToastOptions) => {
  let timeout = 1000
  if (options.timeout === 'medium') {
    timeout = 3000
  } else if (options.timeout === 'long') {
    timeout = 5000
  }

  const showToast = () => {
    selectToaster(options.toasterProps).show({
      message,
      intent,
      timeout,
      icon: options.icon
    })
  }

  if (!options.delayed) {
    showToast()
  } else {
    setTimeout(() => {
      showToast()
    }, 200)
  }
}

export const toast = { success, failure, warning, info }
