import { AnchorButton, Button, Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import React from 'react'
import ReactDOM from 'react-dom'

const menuRoot = () => document.getElementById('header-menu-root')

interface Props {
  text: string
  ahref?: string
  children?: JSX.Element[] | JSX.Element
  menuClassName?: string
}

export default class HeaderMenu extends React.Component<Props> {
  el: HTMLElement

  constructor(props: Props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    menuRoot()?.appendChild(this.el)
  }

  componentWillUnmount() {
    menuRoot()?.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(
      this.props.ahref ? (
        <AnchorButton href={this.props.ahref} target="_blank" className="small" minimal text={this.props.text} />
      ) : (
        <Popover2
          minimal
          content={<Menu className={this.props.menuClassName}>{this.props.children}</Menu>}
          placement="bottom-start"
          transitionDuration={0}
          portalClassName="headerMenuPortal"
          popoverClassName="headerMenuPopover"
          interactionKind="hover"
        >
          <Button minimal className="small" text={this.props.text} />
        </Popover2>
      ),
      this.el
    )
  }
}
