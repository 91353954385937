import { Classes, Tooltip2 } from '@blueprintjs/popover2'
import { FC, useState } from 'react'

import './params.css'

export type AnswerLevel = 'loose' | 'medium' | 'strict'

interface Props {
  onChange: (answerLevel: AnswerLevel) => void
}

const toggleMapping: { [key in AnswerLevel]: AnswerLevel } = {
  strict: 'medium',
  medium: 'loose',
  loose: 'strict'
}

const DEFAULT_VALUE: AnswerLevel = 'medium'

const AnswerLevelPicker: FC<Props> = props => {
  const [value, setValue] = useState<AnswerLevel>(DEFAULT_VALUE)
  const isMutatedCls = value === DEFAULT_VALUE ? '' : 'mutated'

  const toggleValue = () => {
    props.onChange?.(toggleMapping[value])
    setValue(toggleMapping[value])
  }

  return (
    <Tooltip2
      content={
        <span>
          The level of NLG generation OpenBook will use to generate a response: <br />
          <ul>
            <li>
              <strong>"strict"</strong> will not use NLG at all and return the facts that answers the question;
            </li>
            <li>
              <strong>"medium"</strong> will combine facts using a relatively safe level NLG;
            </li>
            <li>
              <strong>"loose"</strong> will use full NLG to answer and may lead to falsehoods.
            </li>
          </ul>
        </span>
      }
      placement="top"
      className={Classes.TOOLTIP2_INDICATOR}
    >
      <span className={`unselectable ${isMutatedCls}`} onClick={toggleValue}>
        answer-level={value}
      </span>
    </Tooltip2>
  )
}

export default AnswerLevelPicker
