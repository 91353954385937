import { Button, Callout, Card, H5, InputGroup, Intent } from '@blueprintjs/core'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'

import { useAuth, useClient } from '../../supabase'

import style from './style.module.css'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLogin, setLogin] = useState(true)
  const [error, setError] = useState<string | undefined>()
  const [message, setMessage] = useState<string | undefined>()
  const { isAuthenticated } = useAuth()
  const client = useClient()
  const navigate = useNavigate()

  useEffect(() => {
    setEmail('')
    setPassword('')
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated])

  const externalAuth = async (provider: 'google' | 'github' | 'linkedin') => {
    const { error } = await client.auth.signIn({ provider })

    setError(error?.message)
  }

  const login = async () => {
    const { error } = await client.auth.signIn({ email, password })
    setError(error?.message)
  }

  const register = async () => {
    const { user, error } = await client.auth.signUp({ email, password })
    setError(error?.message)

    if (user?.confirmation_sent_at) {
      setMessage(
        'A confirmation link was sent to your e-mail address. Please confirm your e-mail address before logging in'
      )
    }
  }

  const toggleView = () => {
    setError(undefined)
    setLogin(!isLogin)
  }

  return (
    <div className="content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card style={{ width: 400 }}>
        <H5>OpenBook</H5>
        Welcome to OpenBook Playground. Please register or login to get started. If you do not already have an account,
        you will need to contact us to use the playground.
        <br /> <br />
        {message && (
          <Callout intent={Intent.PRIMARY} className={style.error}>
            {message}
          </Callout>
        )}
        {error && (
          <Callout intent={Intent.DANGER} className={style.error}>
            {error}
          </Callout>
        )}
        <div>
          <InputGroup
            autoFocus
            value={email}
            placeholder="Email address"
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <InputGroup
            type="password"
            placeholder="Password"
            value={password}
            style={{ margin: '5px 0 15px 0' }}
            onChange={(e) => setPassword(e.currentTarget.value)}
            onKeyDown={(e) => e.key === 'Enter' && login()}
          />
          {/* <a href="" onClick={() => register()}>
            <b>Forgot password?</b>
          </a> */}

          {isLogin ? (
            <>
              <Button fill text="Login" intent={Intent.PRIMARY} onClick={login} className={style.loginButton} />
              <div className={style.toggleLabel}>
                Don't have an account?{' '}
                <a href="#" onClick={toggleView}>
                  <b>Sign up</b>
                </a>
              </div>
            </>
          ) : (
            <>
              <Button fill text="Continue" intent={Intent.PRIMARY} onClick={register} className={style.loginButton} />
              <div className={style.toggleLabel}>
                Already have an account?{' '}
                <a href="#" onClick={toggleView}>
                  <b>Log in</b>
                </a>
              </div>
            </>
          )}
          <div className={style.or}> Or</div>
          <div className={style.socialContainer}>
            <div className={style.socialSubContainer}>
              <Button
                text="Continue with Google"
                onClick={() => externalAuth('google')}
                icon={<SocialIcon network="google" style={{ height: 25, width: 25 }} />}
                className={style.socialButton}
                fill
              />
              <Button
                text="Continue with GitHub"
                onClick={() => externalAuth('github')}
                icon={<SocialIcon network="github" style={{ height: 25, width: 25 }} />}
                className={style.socialButton}
                fill
              />
              <Button
                text="Continue with LinkedIn"
                onClick={() => externalAuth('linkedin')}
                icon={<SocialIcon network="linkedin" style={{ height: 25, width: 25 }} />}
                fill
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Login
