import { SupabaseClient } from '@supabase/supabase-js'
import { useContext } from 'react'

import { SupabaseContext } from './context'

const useClient = (): SupabaseClient => {
  const client = useContext(SupabaseContext)
  if (client === undefined) {
    throw Error('No client has been specified using.')
  }

  return client
}

export default useClient
