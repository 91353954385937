import { InputGroup, Button } from '@blueprintjs/core'
import _ from 'lodash'
import { FC, useEffect, useRef, useState } from 'react'

import { isCompilingRevision, useDebugStore } from '../../../../stores/debug'
import storage from '../../../../utils/storage'

interface Props {
  queryPending: boolean
  sendQuery: (query: string) => void
  bookId?: string
  artefactId?: string
}

const QueryBox: FC<Props> = (props) => {
  const historyKey = `sent_history_${props.bookId}`
  const [query, _setQuery] = useState('')
  const [history, setHistory] = useState<string[]>(storage.get<string[]>(historyKey) || [])
  const [historyIndex, setHistoryIndex] = useState(-1)
  const isCompiling = useDebugStore((state) => isCompilingRevision(state.revisions, props.artefactId))

  useEffect(() => {
    const handler = (e: KeyboardEvent) => e.key === '/' && inputRef.current?.focus()
    document.addEventListener('keyup', handler)
    return () => {
      document.removeEventListener('keyup', handler)
    }
  }, [])

  const inputRef = useRef<HTMLInputElement>(null)

  const setQuery = (value: string) => {
    if (!props.queryPending || value === '') {
      _setQuery(value)
    }
  }

  const addMessageToHistory = (text: string) => {
    if (_.last(history) !== text) {
      const newHistory = [...history, text]

      setHistory(newHistory)
      setHistoryIndex(0)

      storage.set(historyKey, _.takeRight(newHistory, 10))
    }
  }

  const recallHistory = (direction: string) => {
    if (!history.length) {
      return
    }

    let newIndex = direction === 'ArrowUp' ? historyIndex - 1 : historyIndex + 1

    if (newIndex < 0) {
      newIndex = history.length - 1
    } else if (newIndex >= history.length) {
      newIndex = 0
    }

    setQuery(history[newIndex])
    setHistoryIndex(newIndex)
  }

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' && query.length) {
      sendQuery()
    } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      recallHistory(e.key)
    }
  }

  const sendQuery = () => {
    addMessageToHistory(query)
    props.sendQuery(query)

    setQuery('')
  }

  const hasRevision = props.artefactId !== undefined && props.artefactId !== 'none' && props.artefactId !== 'undefined'

  const getPlaceholder = () => {
    if (!hasRevision) {
      return 'Please publish your book or select a revision before you can query your book'
    }
    return !isCompiling ? 'Ask your book a question...' : 'Please wait while your book is compiling...'
  }

  return (
    <InputGroup
      large
      fill
      autoFocus
      inputRef={inputRef}
      disabled={!hasRevision || isCompiling}
      placeholder={getPlaceholder()}
      className={'query ' + (props.queryPending ? 'pending' : '')}
      onKeyDown={onKeyDown}
      value={query}
      maxLength={150}
      onChange={(e) => setQuery(e.currentTarget.value)}
      rightElement={
        <Button
          disabled={props.queryPending || !hasRevision || isCompiling || !query.length}
          minimal
          icon="send-message"
          onClick={sendQuery}
        />
      }
    />
  )
}

export default QueryBox
