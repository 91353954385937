import { Button, InputGroup } from '@blueprintjs/core'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { DialogBody, DialogFooter, DialogWrapper } from '../../../components/Dialog'
import { useBookStore } from '../../../stores/books'
import { pageFromLocation } from '../Header/EditToggle'

interface Props {
  isOpen: boolean
  toggle: () => void
}

const OpenBookDialog: FC<Props> = (props) => {
  const [filter, setFilter] = useState('')
  const [books] = useBookStore((state) => [state.books])
  const navigate = useNavigate()
  const location = useLocation()
  const { isDebug } = pageFromLocation(location)

  useEffect(() => {
    setFilter('')
  }, [props.isOpen])

  return (
    <>
      <DialogWrapper isOpen={props.isOpen} onClose={props.toggle} title="Open Book">
        <DialogBody>
          <InputGroup
            autoFocus
            placeholder="Filtrer"
            value={filter}
            onChange={(e) => setFilter(e.currentTarget.value)}
            maxLength={30}
            style={{ marginBottom: 10 }}
          />

          <div style={{ height: 130, overflowY: 'auto' }}>
            {Object.entries(books)
              .filter(([key, value]) => {
                const text = filter.toLowerCase()
                return (
                  key.toLowerCase().includes(text) ||
                  value.description.toLowerCase().includes(text) ||
                  value.title.toLowerCase().includes(text)
                )
              })
              .map(([key, { title, bookId, artefactId }]) => {
                return (
                  <div key={key} style={{ marginTop: 5 }}>
                    <Button
                      text={`${title} | ${key}  `}
                      rightIcon="open-application"
                      onClick={() => {
                        navigate(`/books/${bookId}/${artefactId}/${isDebug ? 'debug' : 'edit'}`)
                        props.toggle()
                      }}
                    />
                  </div>
                )
              })}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button text="Close" onClick={props.toggle} />
        </DialogFooter>
      </DialogWrapper>
    </>
  )
}

export default OpenBookDialog
