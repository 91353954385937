import { Button, H5, Intent } from '@blueprintjs/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

import style from './pinned.module.css'

interface Props {
  queries: { question: string }[]
  onSendQuery: (query: string) => void
  onRemoveQuery: (query: string) => void
}

const Pinned: React.FC<Props> = (props) => {
  return (
    <PerfectScrollbar className={style.container}>
      <H5>Saved Queries</H5>

      {props.queries.map((x) => (
        <div key={x.question} className="savedQueries">
          <Button onClick={() => props.onSendQuery(x.question)} minimal>
            {x.question}
          </Button>

          <div className="trash">
            <Button icon="trash" intent={Intent.DANGER} onClick={() => props.onRemoveQuery(x.question)} />
          </div>
        </div>
      ))}
    </PerfectScrollbar>
  )
}

export default Pinned
