import { Menu, MenuItem } from '@blueprintjs/core'
import FileSaver from 'file-saver'
import moment from 'moment'
import { FC } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import { useNavigate, useParams } from 'react-router-dom'

import { Turn } from '..'
import FileMenu from '../../../App/Menus/FileMenu'
import HeaderMenu from '../../../App/Menus/HeaderMenu'

interface Props {
  turns: Turn[]
  setTurns: (turns: Turn[]) => void
}

const DebugMenu: FC<Props> = (props) => {
  const navigate = useNavigate()
  const params = useParams()

  const clearTranscript = () => props.setTurns([])
  const switchToEdit = () => navigate(`/books/${params.bookId}/${params.artefactId}/edit`, { replace: true })
  const downloadJson = () =>
    FileSaver(new Blob([JSON.stringify(props.turns, undefined, 2)]), `transcript_${params.bookId}.json`)
  const downloadAsTxt = () => {
    const messages = props.turns
      .map((x) => {
        const time = moment(x.user_question_ts).toLocaleString()
        return `[${time}] User: ${x.user_question_text}\n[${time}] Bot: ${x.answer_text}`
      })
      .join('\n\n')

    const file = `OpenBook\nBook ID: ${params.bookId!}\nRevision: ${params.artefactId}\n\n${messages}`
    FileSaver(new Blob([file]), `transcript_${params.bookId}.txt`)
  }

  const keyHandlers = { switchToEdit }
  const keyMap = { switchToEdit: ['ctrl+.', 'command+.'] }

  return (
    <div>
      <GlobalHotKeys handlers={keyHandlers} keyMap={keyMap || {}} />
      <FileMenu />
      <HeaderMenu text="Book">
        <MenuItem
          text={
            <span>
              Switch to <strong>Edit Mode</strong>
            </span>
          }
          icon="edit"
          labelElement="⌘ ."
          onClick={switchToEdit}
        />
      </HeaderMenu>
      <HeaderMenu text="Chat">
        <Menu>
          <MenuItem text="Clear History" icon="reset" onClick={clearTranscript} />
          <MenuItem text="Download Transcript" icon="cloud-download">
            <MenuItem text="Full details (.json)" onClick={downloadJson} />
            <MenuItem text="Read format (.txt)" onClick={downloadAsTxt} />
          </MenuItem>
        </Menu>
      </HeaderMenu>
      <HeaderMenu text="API Reference" ahref="https://openbook.botpress.dev/redoc" />
    </div>
  )
}

export default DebugMenu
