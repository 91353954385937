import { SupabaseClient } from '@supabase/supabase-js'
import axios, { AxiosError } from 'axios'

import { BookEntry } from './stores/books'
import { getToken, setToken } from './utils/auth'
import { toast } from './utils/toaster'

const baseURL = 'https://openbook.botpress.cloud'

export interface Credentials {
  clientId: string
  clientSecret: string
}

export interface TokenResponse {
  access_token: string
  expires_in: number
  scope: string
  token_type: string
}

export type RevisionStatus = 'PENDING' | 'DONE' | 'COMPILING'

interface ArtifactState {
  success: boolean
  state: {
    progress: number
    status: RevisionStatus
    err?: {
      message: string
    }
  }
}

export type Feedback = 1 | 2 | 3 | 4 | 5

export interface Artefact {
  created_at: Date
  artifact_id: string
}

interface ArterfactList {
  success: boolean
  artifacts: Artefact[]
}

interface BookQueryResult {
  success: boolean
  id: string
  answer: string
  debug: any
}

interface AuthResponse {
  success: boolean
  error?: string
  token?: string
  expiry?: number
}

export interface StoredInfo {
  books: BookEntry
  saveDate: Date
}

class Api {
  private getAxiosConfig(bookId: string) {
    return {
      baseURL,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'X-App-Id': bookId
      }
    }
  }

  async refreshToken(clientId: string, clientSecret: string): Promise<TokenResponse | undefined> {
    try {
      const { data } = await axios.post<TokenResponse>(
        '/oauth2/token',
        `client_id=${clientId}&client_secret=${clientSecret}&grant_type=client_credentials&scope=model`,
        { baseURL }
      )
      setToken(data)

      return data
    } catch (err) {
      console.error(err)

      if (err instanceof AxiosError) {
        toast.failure(`Error refreshing token: ${err.response?.data?.error_description}`)
      }
    }
  }

  async buildBook(bookId: string, bookArchive: any) {
    const formData = new FormData()
    formData.append('book', bookArchive, 'book.tgz')

    const { data } = await axios.post<{ success: boolean; artifact_id: string }>(
      '/book',
      formData,
      this.getAxiosConfig(bookId)
    )

    return data
  }

  async listArtifacts(bookId: string) {
    const { data } = await axios.get<ArterfactList>('/book/artifacts', this.getAxiosConfig(bookId))

    return data
  }

  async getArtifactState(bookId: string, artifact: string) {
    const { data } = await axios.get<ArtifactState>(`/book/${artifact}`, this.getAxiosConfig(bookId))

    return data
  }

  async queryBookArtifact(
    bookId: string,
    artifact: string,
    query: string,
    history: string[],
    answerLevel: 'strict' | 'medium' | 'loose' = 'strict'
  ) {
    const { data } = await axios.post<BookQueryResult>(
      `/book/${artifact}/query`,
      { query, history, answer_level: answerLevel },
      this.getAxiosConfig(bookId)
    )

    return data
  }

  async sendQueryFeedback(bookId: string, artifact: string, query: string, feedback: Feedback = 5) {
    const { data } = await axios.post<{ success: boolean }>(
      `/book/${artifact}/query/${query}/feedback`,
      { feedback },
      this.getAxiosConfig(bookId)
    )

    return data
  }

  async loadUserData(
    client: SupabaseClient
  ): Promise<{ client_id?: string; client_secret?: string; data?: StoredInfo }> {
    const { data, error } = await client.from<any>('users').select()

    if (data?.length) {
      const { client_id, client_secret, payload } = data[0]
      return { client_id, client_secret, data: payload }
    }

    return { client_id: '', client_secret: '', data: {} as any }
  }

  async saveUserData(client: SupabaseClient, items: StoredInfo) {
    const user = client.auth.user()
    await client
      .from('users')
      .update([{ payload: items }])
      .match({ user_id: user?.id })
  }
}

export default new Api()
