import _ from 'lodash'
import moment from 'moment'
import create from 'zustand'

import api, { Artefact, RevisionStatus } from '../api'

type RevisionWithStatus = {
  status?: RevisionStatus
} & Artefact

interface State {
  revisions: RevisionWithStatus[]
  loadRevisions: (bookId: string) => void
  updateRevision: (artefactId: string, status: RevisionStatus) => void
}

export const isCompilingRevision = (revisions: RevisionWithStatus[], revisionId?: string) =>
  !!revisions.find((x) => x.artifact_id === revisionId && x.status === 'COMPILING')

export const useDebugStore = create<State>()((set, get) => ({
  revisions: [],
  loadRevisions: async (bookId: string) => {
    const revisions = await api.listArtifacts(bookId)
    set({
      revisions: revisions.artifacts.sort((a, b) => moment(b.created_at).diff(moment(a.created_at))).slice(0, 12)
    })
  },
  updateRevision: (artefactId: string, status: RevisionStatus) => {
    set((state) => {
      const item = state.revisions.find((x) => x.artifact_id === artefactId)!
      const newItem = item ? { ...item, status } : { artifact_id: artefactId, status, created_at: new Date() }
      return { revisions: [newItem, ...state.revisions.filter((x) => x !== item)] }
    })
  }
}))
