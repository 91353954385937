import { Classes, Dialog, DialogProps } from '@blueprintjs/core'
import cx from 'classnames'
import React, { FC } from 'react'

export interface Props extends DialogProps {
  onSubmit?: () => void
  children: any
  height?: number
  id?: string
}

export const DialogWrapper: FC<Props> = (props) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const target = e.target as HTMLFormElement
    if (!props.id || props.id === target.id) {
      props.onSubmit!()
    }
  }

  return (
    <Dialog transitionDuration={0} canOutsideClickClose={false} canEscapeKeyClose enforceFocus={false} {...props}>
      {props.onSubmit ? (
        <form id={props.id} onSubmit={onSubmit}>
          {props.children}
        </form>
      ) : (
        props.children
      )}
    </Dialog>
  )
}

export const DialogBody = ({ children, className }: { children: any; className?: string }) => {
  return <div className={cx(Classes.DIALOG_BODY, Classes.UI_TEXT, className)}>{children}</div>
}

export const DialogFooter = ({ children }: any) => {
  return (
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>{children}</div>
    </div>
  )
}
