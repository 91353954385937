import { H2 } from '@blueprintjs/core'
import _ from 'lodash'

import { useBookStore } from '../../stores/books'
import FileMenu from '../App/Menus/FileMenu'

import BookCard from './BookCard'
import Templates from './Templates'

const Home = () => {
  const books = useBookStore((state) => _.orderBy(state.books, 'lastOpened', 'desc'))

  return (
    <div className="content" style={{ padding: '40px' }}>
      {books?.length > 0 && (
        <>
          <H2>Recently Opened</H2>
          <div style={{ display: 'flex', margin: '20px 0 40px 0' }}>
            {books.map((book) => (
              <BookCard key={book.bookId} book={book} />
            ))}
          </div>
        </>
      )}

      <Templates />
      <FileMenu />
    </div>
  )
}

export default Home
