import { FC } from 'react'
import { useParams } from 'react-router-dom'

import BuildProgress from './BuildProgress'

interface Props {
  isConnected?: boolean
  statusMessage?: string
}

const StatusBar: FC<Props> = (props) => {
  const params = useParams()

  return (
    <div className="footer">
      <div className="footer-section-left">
        <span className="bp4-text-small">
          <strong>OpenBook</strong> Playground <span>v0.1-alpha</span>
        </span>
      </div>
      <div>{/* Middle section */}</div>
      <div className="footer-section-right">
        <BuildProgress bookId={params.bookId} artefactId={params.artefactId} />
        <div className="server-status">
          <span className="bp4-text-small">
            {/* <ConnectionStatus /> */}
            {/* <Icon icon="dot" size={20} color="limegreen" />{" "}
    <strong>Connected</strong> */}
          </span>
        </div>
      </div>
    </div>
  )
}

export default StatusBar
